import React from "react";
import "./Hero.css";
import { NavLink } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import img1 from "../../assets/img/industry/hero_copy.jpg";
// import img1 from "../../assets/img/industry/1.jpeg";
// import img2 from "../../assets/img/industry/2.jpeg";
// import img3 from "../../assets/img/industry/3.jpeg";
// import img4 from "../../assets/img/industry/4.jpeg";
// import img5 from "../../assets/img/industry/5.jpeg";
// import img6 from "../../assets/img/industry/6.jpeg";
// import img7 from "../../assets/img/industry/7.jpeg";
// import img8 from "../../assets/img/industry/8.jpeg";
// import img9 from "../../assets/img/industry/9.jpeg";
// import img10 from "../../assets/img/industry/10.jpeg";
// import img11 from "../../assets/img/industry/11.jpeg";
// import img12 from "../../assets/img/industry/12.jpeg";
// import img13 from "../../assets/img/industry/13.jpeg";
// import img14 from "../../assets/img/industry/14.jpeg";
// import img15 from "../../assets/img/industry/15.jpeg";

export default function Hero() {
  const slides = [
    {
      url: img1,
    },
    // ,
    // {
    //     url: img2
    // },
    // {
    //     url: img3
    // },
    // {
    //     url: img4
    // },
    // {
    //     url: img5
    // },
    // {
    //     url: img6
    // },
    // {
    //     url: img7
    // },
    // {
    //     url: img8
    // },
    // {
    //     url: img9
    // },
    // {
    //     url: img10
    // },
    // {
    //     url: img11
    // },
    // {
    //     url: img12
    // },
    // {
    //     url: img13
    // },
    // {
    //     url: img14
    // },
    // {
    //     url: img15
    // },
  ];

  return (
    <>
      <section className="main-slider">
        <Carousel
          autoPlay={true}
          emulateTouch={false}
          infiniteLoop={true}
          showArrows={false}
          showStatus={false}
          showIndicators={true}
          showThumbs={false}
          interval={5000}
        >
          {slides.map((slide, i) => {
            return (
              <div key={i}>
                <img src={slide.url} alt="zai-chem" />
                <div className="content">
                  <div className="tp-caption">
                    <h4>We Provide</h4>
                  </div>
                  <div className="tp-caption mt-3">
                    <h2>
                      Best <span> Industrial</span> Service{" "}
                    </h2>
                  </div>
                  <div className="tp-caption mt-3 w-75">
                    <div className="text">
                      We are the best guarenteed company to serve you. We are
                      dedicated to help you any time.
                    </div>
                  </div>
                  <div className="tp-caption mt-5 tp-resizeme">
                    <div className="btn-box">
                      <NavLink
                        to="/services"
                        className="theme-btn btn-style-two a"
                      >
                        Learn more{" "}
                        <span className="fa fa-angle-double-right"></span>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </section>
    </>
  );
}
