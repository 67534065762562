import React from "react";
import "./SpecificProduct.css";
import { NavLink, useParams } from "react-router-dom";
import img1 from "../../assets/img/products/specific/multiColorDrops.png";

export default function SpecificProduct({ theme }) {
  const textileChemicals = [
    {
      title: "ZAIPIL-NG",
      contentbox: (
        <div>
          <h1>ZAIPIL-NG</h1>
          <p>
            ZAIPIL-NG is a valuable textile finishing agent particularly for use
            as a crease proofing agent on cellulosic textile materials including
            blends of such materials with other fibers such as polyamides and
            polyesters. Although it may be employed as a conventional
            crease-proofing finish in the manner described in U.S. Pat. No.
            2,731,364, its present commercial importance is as a postcure
            crease-proofing finish of the type employable in the process
            described in U.S. Pat. No. 2,974,432.
          </p>
          <h2>Benefits:</h2>
          <ul>
            <li>The improved dimensional stability and shape retention</li>
            <li>The fewer tendencies to creasing</li>
            <li>A higher durability and that they are easier to iron</li>
            <li>Fabrics are softer and smoother</li>
            <li>
              Show less change in shade and fewer tendencies to pilling,
              especially of fiber blends.
            </li>
          </ul>
          <p>
            ZAIPIL-NG imparts durable press properties to cellulosic textile
            materials and their blends.
          </p>
          <h2>Application:</h2>
          <p>
            ZAIPIL-NG is useful in imparting durable press properties to
            cellulosic textile materials and as such may be applied to such
            materials by any of the conventional procedures used in the textile
            industry. Thus, they may be applied by padding, dipping, spraying,
            immersing and the like. ZAIPIL-NG is superior to the durable press
            products of the prior art processes from the standpoint of the low
            levels of formaldehyde released from cellulosic materials prior to
            the conventional curing procedures. The low emission of formaldehyde
            after the drying operation and prior to the final curing step is an
            important improvement in post-cure durable pressing processing.
            ZAIPIL-NG may be employed with other textile agents and auxiliaries
            such as surface active agents, softeners, brighteners, odorants and
            other crease-proofing agents. ZAIPIL-NG can be diluted in all
            proportions by pouring on cold water. Stock solutions are stable for
            prolonged periods.
          </p>
          <h2>Properties:</h2>
          <table>
            <tr>
              <th>Physical state</th>
              <td>Liquid</td>
            </tr>
            <tr>
              <th>Melting / freezing point</th>
              <td> &lt; -100°C at 1013 hPa</td>
            </tr>
            <tr>
              <th>Boiling point</th>
              <td>Not applicable, substance decomposes at 80°C</td>
            </tr>
            <tr>
              <th>Flash point</th>
              <td>Not detectable</td>
            </tr>
            <tr>
              <th>Explosive properties</th>
              <td>Non explosive</td>
            </tr>
            <tr>
              <th>Self-ignition temperature</th>
              <td>447°C</td>
            </tr>
          </table>
          <br />
          <h2>Storage:</h2>
          <ul>
            <li>Stable for more than 1 year at 20°C in closed containers.</li>
            <li>
              Storage beyond the date specified on the label does not
              necessarily mean that the product is no longer usable. In this
              case however, the properties required for the intended use must be
              checked for quality assurance reasons.
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: "ZAIFIX RP-50",
      contentbox: (
        <>
          <h2>ZAIFIX RP-50</h2>
          <p>
            ZAIFIX RP-50 is low formaldehyde based, low molecular weight
            precondensate (Fixer).
          </p>

          <h3>Description:</h3>
          <p>
            Important applications of ZAIFIX RP-50 is fixing of reactive and
            direct dye stuff. Dye fixation has always been remained a serious
            matter in dyeing industry. There are many dyes which give very good
            tintorial strength, but their poor fixation does not allow their use
            on commercial scale. Different techniques are used for the
            improvement of washing fastness of reactive and direct dyes, use of
            cationic surfactants and metal treatment decrease the solubility of
            theses dyes under washing conditions but their effect is not
            permanent. On the other hand, ZAIFIX RP-50 gives more pronounced and
            permanent effect in improving the fixation of these dyes.
          </p>

          <h3>Characteristics:</h3>
          <ul>
            <li>
              Improves the wash fastness and other wet fastness properties of
              dyeing with reactive and dyes.
            </li>
            <li>
              Applicable in conjunction with resin finishing of reactive dyeing.
            </li>
            <li>
              Protects dyeing and prints, produced with reactive dyes against
              hydrolysis and thermocracking.
            </li>
            <li>Very slight dye-specific shade changes.</li>
            <li>
              No or only very slight dye-specific impairment of light fastness.
            </li>
            <li>High exhaustion.</li>
            <li>No harshening of handle.</li>
            <li>Contains low formaldehyde.</li>
          </ul>

          <h3>Benefits:</h3>
          <ul>
            <li>
              Excellent water, perspiration and hot pressing fastness and
              improved fastness to washing.
            </li>
            <li>Very good wash fastness.</li>
            <li>
              No impairment of fastness properties after heat treatments or in
              storage under adverse conditions.
            </li>
            <li>No impairment of fastness properties during steaming.</li>
            <li>Negligible effluent pollution high cost effectiveness.</li>
          </ul>
          <h2>Properties:</h2>

          <table>
            <tr>
              <th>Property</th>
              <th>Value</th>
            </tr>
            <tr>
              <td>Ionic character</td>
              <td>Cationic</td>
            </tr>
            <tr>
              <td>Physical form</td>
              <td>Clear, low-viscosity liquid</td>
            </tr>
            <tr>
              <td>pH</td>
              <td>About 4</td>
            </tr>
            <tr>
              <td>General stability</td>
              <td>
                Stable in hard water and to acids alkalis and electrolytes in
                the concentrations usually encountered in textile processing.
              </td>
            </tr>
            <tr>
              <td>Storage stability</td>
              <td>Stable for more than 1 year at 20°C in closed containers.</td>
            </tr>
            <tr>
              <td>Compatibility</td>
              <td>
                Can be used together with cationic and nonionic substances.
              </td>
            </tr>
            <tr>
              <td>Ecology/toxicology</td>
              <td>
                The usual hygiene and safety rules for handling chemicals should
                be observed in storage, handling and use. The product must not
                be swallowed.
              </td>
            </tr>
          </table>
          <br />
          <h2>Application:</h2>
          <h3>Dissolving/diluting:</h3>
          <p>
            ZAIFIX RP-50 can be diluted in all proportions by pouring on cold
            water. Stock solutions are stable for prolonged periods.
          </p>
          <h3>Required amount:</h3>
          <p>
            The amount of ZAIFIX RP-50 required depends on the type of dye and
            desired shade depth. The best effects are obtained by applying
            ZAIFIX RP-50 as follows:
          </p>
          <ul>
            <li>Exhaust application:</li>
            <ul>
              <li>Reactive dyes 0.5–3% ZAIFIX RP-50</li>
              <li>Direct dyes 1–2% ZAIFIX RP-50</li>
            </ul>
            <li>Continuous application:</li>
            <ul>
              <li>Pad mangle:</li>
              <ul>
                <li>Reactive dyes 10–20 g/l ZAIFIX RP-50</li>
                <li>Direct dyes 20–30 g/l ZAIFIX RP-50</li>
                <li>Temperature about 30°C</li>
                <li>pH about 7</li>
              </ul>
              <li>Open-width washing machine:</li>
              <ul>
                <li>Reactive dyes 2–5 g/l ZAIFIX RP-50</li>
                <li>Direct dyes 3–8 g/l ZAIFIX RP-50</li>
                <li>Temperature about 30°C</li>
                <li>pH about 7</li>
              </ul>
            </ul>
            <li>Aftertreatment of prints:</li>
            <ul>
              <li>Exhaustion:</li>
              <ul>
                <li>1–2% ZAIFIX RP-50 20 min at 30–50°C</li>
                <li>pH 6–7</li>
              </ul>
              <li>Open-width washer:</li>
              <ul>
                <li>2–5 g/l ZAIFIX RP-50</li>
                <li>Temperature about 30°C</li>
                <li>pH about 7</li>
              </ul>
              <li>Resin finishing:</li>
              <ul>
                <li>15–20 g/l ZAIFIX RP-50</li>
              </ul>
              <li>Correction of faulty dyeing:</li>
              <ul>
                <li>
                  If ZAIFIX RP-50 has been applied, simple masking with only a
                  very weak stripping action is enough to permit subsequent
                  re-dyeing or shading.
                </li>
              </ul>
            </ul>
          </ul>
          <h4>NOTES:</h4>
          <p>
            ZAIFIX RP-50 exhausts onto the fiber at a medium rate and have good
            leveling power. A maximum temperature of 40°C and a pH of 6–7 at the
            start of treatment promote good leveling. To optimize the yield of
            the aftertreatment bath at high concentrations, the pH can be
            lowered to about 4 with acetic acid after 5–10 min., almost complete
            exhaustion of ZAIFIX RP-50 is achieved under these conditions.
            Cationic and nonionic softeners can be applied together with ZAIFIX
            RP-50. The best softening effects are obtained by a one-bath
            two-stage procedure the softener being applied first and the goods
            then treated with ZAIFIX RP-50 in the same bath. The wash fastness
            properties attainable depend on the choice of dyes the desired shade
            and the application procedure. In exhaust application with the
            recommended SOLOPHENYL® dyes a rating of 4 can be achieved in the
            wash test at 40°C up to about 1/3 standard depth. Applied in
            conjunction with a resin finish, ZAIFIX RP-50 markedly improves the
            wash fastness properties especially on all-cotton goods or
            polyester/cotton blends (up to rating 4 in the wash test at 40°C and
            max. about 1/1 standard depth).
          </p>
        </>
      ),
    },
    {
      title: "ZAIPERSOL-AL",
      contentbox: (
        <>
          <h1>ZAIPERSOL AL</h1>
          <p>
            Water based, most efficient dispersing agent for pigment pastes.
          </p>
          <p>
            Coating is a complex subject if formulators want to formulate
            defects free and yet obtain the best stable performance coating. The
            most complex part of coating is the wetting and dispersing of
            stabilizing solid particles in the system. Thus, dispersion of solid
            in liquid involved extensive colloid chemistry in order for us to
            understand how these particles can be wetted dispersed and
            eventually stabilized. Stabilization of pigments is the most
            important factor in coating to ensure a well dispersed pigmented
            coating.
          </p>
          <p>
            A pigment is a colouring particle which is insoluble in the
            application media. They appear in different particle forms and sizes
            and are based on chemically completely different structures. The
            optimum size of the particles in order to obtain certain application
            properties like gloss, tinting strength and weather fastness is in
            the range of the wavelengths of visible light. In a powderous form
            they are supplied in the pigments are found in a distribution of
            particle sizes which is caused by the production processes. The
            particle sizes vary around a mean. Additionally, pigments are linked
            together by agglomeration to bigger particles which in fact means
            that the particle size of commercially available pigments is much
            higher than the ideal size.
          </p>
          <p>
            ZAIPERSOL AL is an excellent dispersing agent of this modren era, it
            supports pigment dispersion process by providing control on rheology
            and effective stabilization of pigment particles against
            re-agglomeration and flocculation, it eliminates all the problems
            with the preparation of high solid pastes for titanium dioxide
            white. It avoids gelling problems even in case if use on poor
            quality carbon black pegments. Also gives improved coverge of large
            blotch areas on poor quality fabrics.
          </p>
          <h2>ZAIPERSOL AL Dispersing process:</h2>
          <p>
            Following three processes are meant with the term dispersing at the
            production of coatings. These three steps take place at the same
            time and/or after each other:
          </p>
          <ol>
            <li>
              Destroying of agglomerates:
              <ul>
                <li>
                  Agglomerates forms by molecular forces that may be reinfored
                  by adsorption layers if separation is carried out with ambient
                  air, by liquid bridges if moist materials are processed, and
                  by electorstatic forces in a dry environment. ZAIPERSOL AL
                  destroys all these agglomerates and bring the large particles
                  to finely divided particales.
                </li>
              </ul>
            </li>
            <li>
              Wetting of pigment surfaces:
              <ul>
                <li>
                  ZAIPERSOL AL overcomes two aspects to the wetting of pigment
                  particles:
                </li>
                <ol type="a">
                  <li>
                    The spreading of a liquid or a binder system over the
                    surface of the pigment particles
                  </li>
                  <li>
                    The soaking of the pigment powder, especially of the
                    agglomerates, with liquid components
                  </li>
                </ol>
              </ul>
            </li>
            <li>
              Stabilisation of dispersion:
              <ul>
                <li>
                  In any event, ZAIPERSOL AL levels out the differences in IEP
                  between the individual pigments.
                </li>
              </ul>
            </li>
          </ol>
          <img src={img1} alt="multi color drops"></img>
        </>
      ),
    },
    {
      title: "Zaibind-ZT",
      contentbox: (
        <>
          <h1>Zaibind ZT</h1>
          <p>Acrylic binder for water based pigment printing</p>
          <h2>Properties:</h2>
          <ul>
            <li>Good elasticity</li>
            <li>Excellent softness</li>
            <li>Good resistant to solvents</li>
            <li>Excellent heat resistance</li>
            <li>Excellent wet rub fastness</li>
            <li>Excellent dry rub fastness</li>
          </ul>
          <h2>Applications:</h2>
          <p>Pigment printing:</p>
          <p>With Water base System:</p>
          <ol>
            <li>Zaibind ZT: 12 to 15 parts</li>
            <li>Synthetic Thickener: 1.5 to 1.6 parts</li>
            <li>Water: 83 to 80 parts</li>
          </ol>
          <p>Total: 100 parts</p>
          <p>Neutralize to pH 8.5 to 9 with liquor ammonia.</p>
          <p>Zaifix RLR: 03 parts</p>
          <p>(Add Fixer just before printing.)</p>
          <p>Binder dosages will be depending on the % shade of pigment.</p>
          <p>
            Note: The neutralization should be done only after the addition of
            catalyst. Little excess of Liquor Ammonia would not in any way harm
            the print paste. Print paste should be filtered well before it is
            taken for printing.
          </p>
          <h2>Storage:</h2>
          <p>
            Zaibind ZT should be stored in a cool dry place having its container
            closed tightly. Once the seal of the drum is opened, the quantity
            must be used immediately.
          </p>
          <h2>Shelf Life:</h2>
          <p>
            If stored in the recommended condition, product has a shelf life of
            one year from the date of manufacturing.
          </p>
          <p>
            Storage beyond the date specified on the label does not necessarily
            mean that the product is no longer usable. In this case however, the
            properties required for the intended use must be checked for quality
            assurance reasons.
          </p>
        </>
      ),
    },
    {
      title: "Zairem SR",
      contentbox: (
        <>
          <div>
            <h2>Zairem SR</h2>
            <p>
              Non Ionic and APEO/NPEO free wetting and stain removing agent.
            </p>
            <h3>Benefits:</h3>
            <ul>
              <li>
                Very good detergency, good rewetting and excellent stain
                removing properties.
              </li>
              <li>
                Suitable for boiling off, kier boiling and bleaching process.
              </li>
              <li>
                Uniform extraction of oils, waxes and other impurities to
                produce an absorbent and clean structure for level dying,
                printing and white finishing etc.
              </li>
              <li>
                ZAIREM SR can be used to disperse the solids generated by
                precipitation, coagulation and other chemical reactions.
              </li>
              <li>
                ZAIREM SR cam be employed in a variety of refining, mixing,
                impregnating and surface-treatment processes.
              </li>
            </ul>
          </div>
          <div>
            <h2>Characteristics:</h2>
            <table>
              <tr>
                <th>Property</th>
                <th>Description</th>
                <th>Value</th>
              </tr>
              <tr>
                <td>Appearance</td>
                <td>Transparent low viscosity fluid</td>
                <td></td>
              </tr>
              <tr>
                <td>pH</td>
                <td>7</td>
                <td></td>
              </tr>
              <tr>
                <td>Ionic nature</td>
                <td>Non Ionic</td>
                <td></td>
              </tr>
              <tr>
                <td>Solubility</td>
                <td>
                  Dissolves readily in cold as well as hot water in all
                  proportions.
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Active ingredients</td>
                <td>Aliphatic hydro carbons (as solvent)</td>
                <td>40 P.B.W</td>
              </tr>
              <tr>
                <td></td>
                <td>Polyglycol based esters (as detergent)</td>
                <td>40 P.B.W</td>
              </tr>
              <tr>
                <td></td>
                <td>Long chain alcoholic amide (as wetting agent)</td>
                <td>20 P.B.W</td>
              </tr>
              <tr>
                <td></td>
                <td>Chlorinated hydro carbons (aliphatic)</td>
                <td>25 P.B.W</td>
              </tr>
              <tr>
                <td></td>
                <td>Amino – esters</td>
                <td>25 P.B.W</td>
              </tr>
              <tr>
                <td></td>
                <td>Aqua</td>
                <td>50 P.B.W</td>
              </tr>
            </table>
            <br />
            <h2>Application:</h2>
            <ol>
              <li>
                Exhaust Process: use ZAIREM SR 0.5 ~ 1.0 g/l introducing into
                scouring and or bleaching liquors according to conventional
                methods.
              </li>
              <li>
                Semi-Continuous Process: ZAIREM SR is to be introduced in
                scouring and/or bleaching liquors as follows.
              </li>
            </ol>

            <h3>Scouring</h3>
            <table>
              <tr>
                <th>Chemical</th>
                <th>Concentration</th>
              </tr>
              <tr>
                <td>ZAIREM SR</td>
                <td>1~2 g/l</td>
              </tr>
              <tr>
                <td>Soda ash</td>
                <td>2~5 g/l</td>
              </tr>
              <tr>
                <td>50° caustic soda</td>
                <td>5~10 g/l</td>
              </tr>
            </table>
            <br />
            <h3>Bleaching</h3>
            <table>
              <tr>
                <td></td>
                <td>Pad Steam</td>
                <td>H.T</td>
                <td>Padroll</td>
                <td>Padbatch</td>
              </tr>
              <tr>
                <td>ZAIREM SR</td>
                <td>2~4 g/l</td>
                <td>2~4</td>
                <td>1~3</td>
                <td>2~5</td>
              </tr>
              <tr>
                <td>Caustic Soda</td>
                <td>5~10 g/l</td>
                <td>3~7</td>
                <td>2~5</td>
                <td>5~10</td>
              </tr>
              <tr>
                <td>Hydrogen peroxide</td>
                <td>15~30 ml/l</td>
                <td>20~30</td>
                <td>10~20</td>
                <td>30~40</td>
              </tr>
              <tr>
                <td>Stabilizer</td>
                <td>3~5 ml/l</td>
                <td>5~8</td>
                <td>3~15</td>
                <td>3~15</td>
              </tr>
              <tr>
                <td>Lmp. Tenmp.</td>
                <td></td>
                <td></td>
                <td>20C ~30C </td>
                <td></td>
              </tr>
              <tr>
                <td>Pick up</td>
                <td></td>
                <td></td>
                <td>80C~100C </td>
                <td></td>
              </tr>
              <tr>
                <td>Dwell Temp. C </td>
                <td>102</td>
                <td>120~140</td>
                <td>90~95</td>
                <td>20~30</td>
              </tr>
              <tr>
                <td>Dwell Time</td>
                <td>4~10 min.</td>
                <td>1~2 min</td>
                <td>1~2 hrs.</td>
                <td>14~16 hrs.</td>
              </tr>
            </table>
            <br />
            <h2>Storage:</h2>
            <p>
              The “Best use before end” date of each batch shown on the product
              label.
            </p>
            <p>
              Storage beyond the date specified on the label does not
              necessarily mean that the product is no longer usable. In this
              case however, the properties required for the intended use must be
              checked for quality assurance reasons.
            </p>
          </div>
        </>
      ),
    },
    {
      title: "Zaisoft TW",
      contentbox: (
        <>
          <h1>ZAISOFT TW</h1>
          <p>
            ZAISOFT TW is a nonionic macro co-emulsion of highly saturated fatty
            acids-amido-functional silicone fluid.
          </p>
          <h2>Properties</h2>
          <p>
            ZAISOFT TW improves the hand of textiles. 'Hand' means the softness
            and smoothness of the textile. ZAISOFT TW greatly improves the wear
            ease and comfort of textiles. ZAISOFT TW offers softness, maintains
            the whiteness and natural absorbency of cellulosic fabrics, and is
            more durable than traditional hydrophilic silicones. The special
            orientation of ZAISOFT TW on the surface of fiber results extreme
            hydrophilicity. ZAISOFT TW bonds more strongly to the fiber surface
            than glycol and quat-functional polysiloxanes. This leads to better
            exhaustibility and wash resistance. ZAISOFT TW imparts stain release
            property, bouncy effect, excellent water absorbency, cotton–touch
            feel with wrinkle resistance and smooth handle to polyester fibers.
          </p>
          <h2>Application</h2>
          <p>
            ZAISOFT TW is used for hydrophilic treatment of fibers and textiles
            of all kinds. On hydrophilic substrates such as cellulosic, the
            hydrophilic properties are not usually affected, whereas hydrophobic
            substrates such as polyesters usually experience a clear improvement
            in wetting properties. ZAISOFT TW provides woven goods and knitwear
            with a silky soft, supple and smooth hand. ZAISOFT TW is used for
            padding, in the exhaust process and can also be used on its own or
            together with organic fabric softeners, polyethylene waxes and other
            finishing agents. The compatibility should be tested on a
            case-by-case basis.
          </p>
          <h2>Processing</h2>
          <p>
            For padding, it is recommended to use 10 to 30 g/l of ZAISOFT TW.
            For the exhaust process, 1 to 3% ZAISOFT TW is used, based on the
            weight of the textile.
          </p>
          <h2>Storage</h2>
          <p>
            Check the 'Best use before end' date on the label. Storage beyond
            the date specified on the label does not necessarily mean that the
            product is no longer usable. In this case however, the properties
            required for the intended use must be checked for quality assurance
            reasons.
          </p>
          <h2>Typical General Characteristics</h2>

          <table>
            <tr>
              <td>Appearance:</td>
              <td>milky-white macro emulsion</td>
            </tr>
            <tr>
              <td>Solids content (microwave oven):</td>
              <td>approx. ______ %</td>
            </tr>
            <tr>
              <td>pH-Value:</td>
              <td>at 25 °C 4 - 6</td>
            </tr>
            <tr>
              <td>Ionic nature:</td>
              <td>Nonionic</td>
            </tr>
          </table>
        </>
      ),
    },
    {
      title: "Zaisilk SM-50",
      contentbox: (
        <>
          <h1>Zaisilk SM-50</h1>
          <h2>Character</h2>
          <p>Linear silicon co polymer emulsion</p>

          <h3>Features:</h3>
          <ul>
            <li>
              Imparts durable softness and silkiness to a broad range of fabrics
              because of its good shear stability at high temperature
            </li>
            <li>
              Slippery, full and in-depth softness on polyester, polyester
              blends and cotton.
            </li>
            <li>
              Produces soft fluffy touch and wool like effect to the fabrics.
            </li>
            <li>
              Increases elasticity, sewbility and stretch recovery and reduces
              abrasion loss.
            </li>
            <li>
              Improves the wash-and-wear properties and the crease recovery
              angle.
            </li>
            <li>
              Good washing and dry cleaning stability & good running stability
              on padder.
            </li>
          </ul>

          <h2>Performance Test Data:</h2>

          <h3>Temperature and Shear Stability:</h3>

          <p>
            Test condition: 20 g/L emulsion, 70 °C, pH=10, 2000 rpm, 30 minutes
          </p>

          <table>
            <thead>
              <tr>
                <th>Product</th>
                <th>Test result</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Zaisilk SM-50</td>
                <td>Stable, No oil</td>
              </tr>
            </tbody>
          </table>

          <p>Note: Test data. Actual results may vary</p>

          <h3>Emulsion Stability Under Different Bath pH:</h3>

          <p>Test condition: 20 g/L emulsion, 70 °C</p>

          <table>
            <thead>
              <tr>
                <th>Product</th>
                <th>pH=3</th>
                <th>pH=7</th>
                <th>pH=9</th>
                <th>pH=11</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Zaisilk SM-50</td>
                <td>Stable, No oil</td>
                <td>Stable, No oil</td>
                <td>Stable, No oil</td>
                <td>Stable, No oil</td>
              </tr>
            </tbody>
          </table>

          <p>Note: Test data. Actual results may vary</p>

          <h3>Properties:</h3>
          <dl>
            <dt>Appearance</dt>
            <dd>Translucent to milky macro emulsion</dd>

            <dt>Chemical character</dt>
            <dd>Amino-modified polysiloxane</dd>

            <dt>Ionic character</dt>
            <dd>Non Ionic</dd>

            <dt>pH of (1% aqueous solution)</dt>
            <dd>7</dd>

            <dt>Dilutability</dt>
            <dd>Dilutable with cold water in any proportion</dd>

            <dt>Compatibility</dt>
            <dd>Good with cationic and anionic products</dd>

            <dt>Solid content</dt>
            <dd>50%</dd>

            <dt>Influence on dyed goods</dt>
            <dd>
              extremely less / no yellowing and does not incur color change &
              color migration
            </dd>

            <dt>Storage stability</dt>
            <dd>At least 12 months under normal conditions.</dd>
          </dl>

          <h2>Potential Applications:</h2>

          <p>
            Zaisilk SM-50 textile softener is easily applied with dilution and
            without pre-dilution in textile finishing processes of either the
            exhaustion or padding type. It can be used for virtually all types
            of fabrics, including polyester, polyester/cotton blends, nylon,
            spandex blends, viscose rayon, wool, cashmere and acrylic fabrics.
            Zaisilk SM-50 is stable in the presence of most textile auxiliaries,
            such as crease (durable press) resist resins, their catalysts, wax
            emulsions, non-ionic products, and cationic products. The use level
            of Zaisilk SM-50 textile softener is subject to the softness
            required, type of fabric and textile finishing process, but typical
            starting use levels and conditions are as follows.
          </p>
          <h2>Standard recipes:</h2>
          <table>
            <tr>
              <th>Pad-dry-cure process</th>
              <td>
                <ul style={{ listStyle: "none" }}>
                  <li> Dosage:10~30 g/L </li>
                  <li> Bath pH:5.5 - 6.5</li>
                  <li> Bath temperature:20~30 °C</li>
                  <li> Pick-up:65 - 85%</li>
                  <li> Drying:100~130 °C for 2~4 minutes</li>
                  <li> Curing:150~180 °C for 1~2 minutes</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>Exhaustion process</th>
              <td>
                <ul style={{ listStyle: "none" }}>
                  <li>Aliphatic hydrocarbons (as solvent) 40 P.B.W</li>
                  <li>Polyglycol based esters (as detergent) 40 P.B.W</li>
                  <li>
                    Long chain alcoholic amide (as wetting agent) 20 P.B.W
                  </li>
                  <li>Chlorinated hydrocarbons (aliphatic) 25 P.B.W</li>
                  <li>Amino – esters 25 P.B.W</li>
                  <li>Aqua 50 P.B.W</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>Garment ﬁnishing process</th>
              <td>
                <ul style={{ listStyle: "none" }}>
                  <li>Aliphatic hydrocarbons (as solvent) 40 P.B.W</li>
                  <li>Polyglycol based esters (as detergent) 40 P.B.W</li>
                  <li>
                    Long chain alcoholic amide (as wetting agent) 20 P.B.W
                  </li>
                  <li>Chlorinated hydrocarbons (aliphatic) 25 P.B.W</li>
                  <li>Amino – esters 25 P.B.W</li>
                  <li>Aqua 50 P.B.W</li>
                </ul>
              </td>
            </tr>
          </table>
          <h2>Precaution in Use:</h2>

          <ul>
            <li>
              Ensure that the Zaisilk SM-50 textile softener solution is
              completely homogenous before using.
            </li>
            <li>Clean the pad bath/exhaustion bath.</li>
          </ul>

          <h2>Package & Storage:</h2>

          <p>
            120 kg in one plastic drum can be stored for 12 months under room
            temperature and hermetic condition.
          </p>
        </>
      ),
    },
    {
      title: "Zaisilk ME-40",
      contentbox: (
        <div>
          <h2>ZAISILK ME-40</h2>
          <h3>SILICONE SOFTENER EMULSION</h3>
          <p>
            ZAISILK ME-40 is a nonionic micro emulsion concentrate of an
            amino-modified silicone fluid.
          </p>
          <h4>Characteristics:</h4>
          <ul>
            <li>
              Imparts a very soft, smooth and elastic touch to woven and knitted
              fabrics
            </li>
            <li>
              Improves the wash-and-wear properties and the crease recovery
              angle and tear strength and reduces abrasion loss
            </li>
            <li>Effects are fast to washing and dry cleaning</li>
            <li>
              Has very little effect on the degree of whiteness when usual
              application conditions are adhered to
            </li>
            <li>
              No adverse effect on the shade or colour fastness properties of
              coloured goods is observed
            </li>
          </ul>
          <h4>Processing</h4>
          <p>
            ZAISILK ME-40 provides permanent effects even without the addition
            of a catalyst. Specific curing conditions need not be adhered to if
            no resins are used.
          </p>
          <p>
            ZAISILK ME-40 can be applied either by padding or by the exhaust
            method but, as with all products containing silicone, the finishing
            liquors should not be subjected to high shear, as this might cause
            instability.
          </p>
          <p>
            5-30 g/l ZAISILK ME-40 are recommended for the pad method. For the
            exhaust method 1-3 %, relative to the weight of goods are adequate
            and the treatment should be carried out at 40-50 °C for 15-20
            minutes. The pH of the liquor should not exceed pH 6. It may need to
            be adjusted with acetic acid.
          </p>
          <p>
            ZAISILK ME-40 can be applied either alone or together with resins
            and other finishing agents. If ZAISILK ME-40 is used together with
            crosslinking agents and other additives, it should be added, in
            diluted form, after all the other products. (This recommendation
            applies generally to the processing of all polysiloxanes). Higher
            crease recovery angles and lower abrasion values can be obtained in
            resin finishes by using ZAISILK ME-40. A wide range of touches can
            be obtained by combining ZAISILK ME-40 with conventional softening
            agents and other additives such as fillers based on polyacrylate.
          </p>
          <h2>Typical recipes:</h2>
          <h3>Woven and knitted materials of synthetic fibers</h3>
          <p>0.5 ml/l acetic acid 60 %</p>
          <p>10-30 g/l ZAISILK ME-40</p>
          <p>pad, liquor pick-up about 50 %, dry at 120-150 °C</p>
          <h3>Single jersey or interlock cotton fabrics (exhaust method)</h3>
          <p>0.5 ml/l acetic acid 60 %</p>
          <p>2-4 g/l ZAISILK ME-40</p>
          <p>
            10-30 minutes at 30-40 °C, without rinsing, hydroextract, dry at
            100-150 °C
          </p>
          <h2>Storage</h2>
          <p>
            The "Best use before end" date of each batch is shown on the product
            label. Emulsions showing signs of creaming should be agitated before
            use to assure uniform consistency. Storage beyond the date specified
            on the label does not necessarily mean that the product is no longer
            usable. In this case however, the properties required for the
            intended use must be checked for quality assurance reasons.
          </p>
          <h2>Product data</h2>
          <p>Typical general characteristics</p>
          <ul>
            <li>Appearance: clear, crystal transparent</li>
            <li>Solids content (microwave oven): approx. 40 %</li>
            <li>pH-Value: approx. 6</li>
            <li>Ionogenity: nonionic</li>
          </ul>
          <p>
            These figures are only intended as a guide and should not be used in
            preparing specifications.
          </p>
        </div>
      ),
    },
    {
      title: "Zaivil-50,35,30",
      contentbox: (
        <>
          <h1>Zaivil -50, 35, 30</h1>
          <p>A strong and robust adhesive</p>
          <p>
            Zaivil is an aliphatic rubbery synthetic polymer. It belongs to the
            polyvinyl esters family. It is a type of thermoplastic.
          </p>
          <h2>Properties:</h2>
          <ul>
            <li>
              The degree of polymerization of Zaivil is typically 4500 to 5000.
            </li>
            <li>Provides High cohesive strength.</li>
            <li>
              Zaivil’s flexibility improves peel strength by flexing with peel
              stress.
            </li>
            <li>Zaivil resists stress at the bond line.</li>
            <li>
              Zaivil dissipates dynamic stresses of vibration, motion, &amp;
              impact throughout the bond &amp; peel stresses at the bond line.
            </li>
            <li>
              Zaivil’s flexibility and damping resistance resists thermal
              expansion stresses.
            </li>
            <li>
              Gives powerful Adhesion to a variety of substrates allows bonding
              of dissimilar materials.
            </li>
          </ul>
          <h2>Applications:</h2>
          <p>
            Zaivil is used as adhesives for porous materials, particularly for
            wood, paper, and cloth, and as a consolidant for porous building
            stone, in particular sandstone.
          </p>
          <h3>Uses:</h3>
          <ul>
            <li>
              Handle modifier, filling and stiffening agent for textile
              industry:
              <p>
                The greatest amount of Zaivil is used for backcoating carpets,
                strengthening non-wovens or, as filling and stiffening agents,
                for textile finishing. Aqueous dispersions of Zaivil is used for
                finishing and stiffening of textiles. As against the
                conventional starch finishes, this stiffening composition has
                the property that they are not, or only to a minor extent,
                removed from the fiber during laundering and that, therefore, a
                distinct stiffening effect is still present after several
                launderings. Consequently, wash-resistant finishes based on
                Zaivil are used as initial or permanent finishes.
              </p>
            </li>
            <li>
              As wood glue:
              <p>
                Shows high performance at application on wood, making crafts,
                or, other building works. It dries clear.
              </p>
            </li>
            <li>
              as paper adhesive:
              <p>
                Zaivil is used in a wide variety of paper bonding applications
                ranging from corrugated box construction and the lamination of
                printed sheets to packaging material used for all types of
                consumer’s products to the production of large industrial tubes
                and cores used by manufacturers of role goods and other
                materials. Zaivil is also used in paper coatings, paint and
                other industrial coatings, as binder in nonwovens in glass
                fibers. Sanitary napkins, filter paper and in textile finishing
              </p>
            </li>
            <li>
              in handicrafts:
              <p>
                Useful and decorative objects are made using Zaivil as adhesive.
              </p>
            </li>
            <li>
              as wallpaper adhesive:
              <p>Hanging wallpaper is very easy with Zaivil.</p>
            </li>
            <li>as a primer for drywall and other substrates</li>
          </ul>
          <h2>Storage:</h2>
          <p>
            Stable for more than 5 year at 25°C in closed containers. Storage
            beyond the date specified on the label does not necessarily mean
            that the product is no longer usable. In this case however, the
            properties required for the intended use must be checked for quality
            assurance reasons.
          </p>
        </>
      ),
    },
    {
      title: "Zaifix RPR",
      contentbox: (
        <>
          <h1>Zaifix RPR</h1>
          <p>
            Zaifix RPR is a highly concentrated pigment fixer widely used in the
            textile industry. It is designed to improve the wash and crock
            fastness of pigments on fabric without compromising on color
            brightness or softness. This pigment fixer is easily dilutable with
            cold water to any proportion, and it has a low formaldehyde content,
            making it a safer and more environmentally friendly option.
          </p>
          <h2>Properties:</h2>
          <ul>
            <li>Highly concentrated on cost-effectiveness</li>
            <li>Improves wash fastness and crock fastness</li>
            <li>Easily dilutable to any proportion with cold water</li>
            <li>
              Low formaldehyde content for improved safety and environmental
              impact
            </li>
            <li>Does not affect the brightness or softness of the colors</li>
          </ul>
          <h2>Typical properties:</h2>
          <ul>
            <li>Appearance: Clear to slightly yellow liquid</li>
            <li>pH: 3.5-4.5</li>
            <li>Ionicity: Non-ionic</li>
            <li>Solubility: Completely soluble in water</li>
            <li>Dilution ratio: 1:5 to 1:50</li>
            <li>Compatibility: Can be used with most pigment systems</li>
          </ul>
          <h2>Catalyst:</h2>
          <p>
            Zaifix RPR is a self-catalyzing system that does not require the
            addition of a separate catalyst.
          </p>
          <h2>Applications:</h2>
          <p>
            Zaifix RPR is commonly used in the textile industry to improve the
            wash and crock fastness of pigments on fabric. It can be easily
            diluted with cold water to any proportion and can be applied using
            padding, spraying, or printing methods. This pigment fixer is
            compatible with most pigment systems and can be used on a variety of
            fibers, including cotton, polyester, and blends.
          </p>
          <h2>Note:</h2>
          <p>
            Zaifix RPR should be applied in accordance with the manufacturer's
            instructions, and fabric compatibility tests should be performed
            before use.
          </p>
          <h2>Storage:</h2>
          <p>
            Zaifix RPR should be stored in a cool, dry place at a temperature
            between 5°C and 25°C.
          </p>
          <h2>Shelf life:</h2>
          <p>
            It has a shelf life of 12 months from the date of manufacture when
            stored as recommended. Once diluted, the solution should be used
            within 24 hours.
          </p>
        </>
      ),
    },
    {
      title: "Zaicrease ACA",
      contentbox: (
        <div>
          <h2>ZAICREASE ACA</h2>
          <p>
            ZAICREASE ACA is a textile finishing agent that is used to impart
            durable press properties to cellulosic textile materials and their
            blends. It is a modified amino compound that cross-links with
            cellulose fibers to provide crease resistance.
          </p>
          <h3>Properties:</h3>
          <ul>
            <li>Provides excellent crease resistance</li>
            <li>Improves wrinkle recovery</li>
            <li>Enhances the durability of the fabric</li>
            <li>
              Low formaldehyde content for improved safety and environmental
              impact
            </li>
            <li>Does not affect the color or hand feel of the fabric</li>
          </ul>
          <h3>Typical properties:</h3>
          <ul>
            <li>Appearance: Clear to slightly yellow liquid</li>
            <li>pH: 6.0-7.0</li>
            <li>Ionicity: Cationic</li>
            <li>Solubility: Completely soluble in water</li>
            <li>Application method: Padding, exhaustion or spraying</li>
            <li>Drying method: Air-dry or tumble dry</li>
            <li>Compatibility: Can be used with most textile auxiliaries</li>
            <li>
              Catalyst: ZAICREASE ACA requires the addition of an acidic
              catalyst, such as sulfuric acid or citric acid, to initiate the
              reaction.
            </li>
          </ul>
          <h3>Applications:</h3>
          <p>
            ZAICREASE ACA is widely used in the textile industry as a durable
            press agent on cellulosic textile materials, including blends of
            such materials with other fibers such as polyamides and polyesters.
            It can be applied using padding, exhaustion or spraying methods and
            can be air-dried or tumble-dried. This finishing agent is compatible
            with most textile auxiliaries and can be used on a variety of
            fibers, including cotton, linen, and rayon.
          </p>
          <p>
            <strong>Note:</strong> ZAICREASE ACA should be applied in accordance
            with the manufacturer's instructions, and fabric compatibility tests
            should be performed before use. The acidic catalyst used should be
            added slowly and stirred well during the addition. Overdosing of the
            catalyst should be avoided.
          </p>
          <h3>Storage:</h3>
          <p>
            ZAICREASE ACA should be stored in a cool, dry place at a temperature
            between 5°C and 25°C.
          </p>
          <h3>Shelf life:</h3>
          <p>
            It has a shelf life of 12 months from the date of manufacture when
            stored as recommended.
          </p>
        </div>
      ),
    },
    {
      title: "Zaigain WT",
      contentbox: (
        <>
          <h2>Zaigain WT</h2>
          <p>
            Zaigain WT is a weight enhancing agent used in the textile industry
            to impart a heavy and bulky effect to the fabric. It is specially
            designed to maintain the soft and elastic hand of the fabric while
            increasing its weight.
          </p>

          <h3>Properties:</h3>
          <ul>
            <li>Enhances the weight of the fabric</li>
            <li>Maintains the soft and elastic hand of the fabric</li>
            <li>Improves the drape of the fabric</li>
            <li>Does not affect the color or appearance of the fabric</li>
            <li>Improves the overall quality and value of the fabric</li>
          </ul>

          <h3>Typical properties:</h3>
          <ul>
            <li>Appearance: White to pale yellow powder</li>
            <li>pH: 5.5-6.5</li>
            <li>Ionicity: Non-ionic</li>
            <li>Solubility: Completely soluble in water</li>
            <li>Application method: Padding or exhaustion</li>
            <li>Drying method: Air-dry or tumble dry</li>
            <li>Compatibility: Can be used with most textile auxiliaries</li>
            <li>
              Catalyst: Zaigain WT does not require the addition of a catalyst.
            </li>
          </ul>

          <h3>Applications:</h3>
          <p>
            Zaigain WT is widely used in the textile industry as a
            weight-enhancing agent for various fabrics. It can be applied using
            padding or exhaustion methods and can be air-dried or tumble-dried.
            This agent is compatible with most textile auxiliaries and can be
            used on a variety of fibers, including cotton, polyester, and rayon.
          </p>

          <p>
            Note: Zaigain WT should be applied in accordance with the
            manufacturer's instructions, and fabric compatibility tests should
            be performed before use. The recommended dosage may vary depending
            on the fabric type, weight, and desired effect. Overdosing should be
            avoided as it may affect the softness and elasticity of the fabric.
          </p>

          <h3>Storage:</h3>
          <p>
            Zaigain WT should be stored in a cool, dry place at a temperature
            between 5°C and 25°C.
          </p>

          <h3>Shelf life:</h3>
          <p>
            It has a shelf life of 12 months from the date of manufacture when
            stored as recommended.
          </p>
        </>
      ),
    },
    {
      title: "Zaipersol-K",

      contentbox: (
        <>
          <h2>ZAIPERSOL-K</h2>
          <p>
            ZAIPERSOL-K is a leveling agent designed for use in reactive and
            direct dyeing processes. It is known for its ability to level and
            distribute dyes evenly throughout the fabric, without adversely
            affecting the shade or brightness of the colors. Additionally, it is
            easy to handle and does not produce foam.
          </p>
          <h3>Properties:</h3>
          <ul>
            <li>
              Acts as a leveling agent for reactive and direct dyeing processes
            </li>
            <li>Does not adversely affect the shade or brightness of colors</li>
            <li>Easy to handle and produces no foam</li>
            <li>Improves the evenness of dye distribution on the fabric</li>
          </ul>
          <h3>Typical properties:</h3>
          <ul>
            <li>Appearance: Clear, colorless liquid</li>
            <li>pH: 5-7</li>
            <li>Ionicity: Nonionic</li>
            <li>Solubility: Soluble in water</li>
            <li>
              Application method: Can be added at any stage of the dyeing
              process
            </li>
            <li>Drying method: Air-dry or tumble dry</li>
            <br />
            <h3>Catalyst</h3>
            <p>ZAIPERSOL-K does not require a catalyst for use.</p>
          </ul>
          <h3>Applications:</h3>
          <p>
            ZAIPERSOL-K is widely used in the textile industry as a leveling
            agent in reactive and direct dyeing processes. It can be added at
            any stage of the dyeing process and is compatible with most textile
            auxiliaries.
          </p>
          <h3>Note:</h3>
          <p>
            ZAIPERSOL-K should be applied in accordance with the manufacturer's
            instructions. The recommended dosage may vary depending on the
            fabric type, weight, and desired effect. Overdosing should be
            avoided as it may affect the overall quality of the fabric.
          </p>
          <h3>Storage:</h3>
          <p>
            ZAIPERSOL-K should be stored in a cool, dry place at a temperature
            between 5°C and 25°C.
          </p>
          <h3>Shelf life:</h3>
          <p>
            It has a shelf life of 12 months from the date of manufacture when
            stored as recommended.
          </p>
        </>
      ),
    },
    {
      title: "Zaipersol-SK",
      contentbox: (
        <>
          <h1>ZAIPERSOL-SK</h1>
          <p>
            <strong>Properties:</strong>
          </p>
          <ul>
            <li>
              Acts as a leveling agent for high-temperature dyeing processes
            </li>
            <li>Reduces foam production during the dyeing process</li>
            <li>Improves the evenness of dye distribution on the fabric</li>
          </ul>
          <p>
            <strong>Typical properties:</strong>
          </p>
          <ul>
            <li>Appearance: Clear, colorless liquid</li>
            <li>pH: 5-7</li>
            <li>Ionicity: Nonionic</li>
            <li>Solubility: Soluble in water</li>
            <li>
              Application method: Can be added at any stage of the dyeing
              process
            </li>
            <li>Drying method: Air-dry or tumble dry</li>
            <li>Catalyst: ZAIPERSOL-SK does not require a catalyst for use.</li>
          </ul>
          <p>
            <strong>Applications:</strong>
          </p>
          <ul>
            <li>
              ZAIPERSOL-SK is widely used in the textile industry as a leveling
              agent for high-temperature dyeing processes.
            </li>
            <li>
              It can be added at any stage of the dyeing process and is
              compatible with most textile auxiliaries.
            </li>
          </ul>
          <p>
            <strong>Note:</strong>
          </p>
          <ul>
            <li>
              ZAIPERSOL-SK should be applied in accordance with the
              manufacturer's instructions.
            </li>
            <li>
              The recommended dosage may vary depending on the fabric type,
              weight, and desired effect.
            </li>
            <li>
              Overdosing should be avoided as it may affect the overall quality
              of the fabric.
            </li>
          </ul>
          <p>
            <strong>Storage:</strong>
          </p>
          <ul>
            <li>
              ZAIPERSOL-SK should be stored in a cool, dry place at a
              temperature between 5°C and 25°C.
            </li>
          </ul>
          <p>
            <strong>Shelf life:</strong>
          </p>
          <ul>
            <li>
              It has a shelf life of 12 months from the date of manufacture when
              stored as recommended.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Zaicrystal-66",
      contentbox: (
        <>
          <h1>ZAICRYSTAL-66</h1>
          <p>
            ZAICRYSTAL-66 is a finishing agent that is generally used to produce
            stiff finishes on textile materials. It provides a crisp and clean
            appearance to the fabric.
          </p>
          <h2>Properties:</h2>
          <ul>
            <li>
              ZAICRYSTAL-66 provides a stiff finish to the fabric without any
              harshening of the handle. It has excellent resistance to water and
              washing.
            </li>
          </ul>
          <h2>Typical Properties:</h2>
          <ul>
            <li>Appearance: Clear to pale yellow liquid</li>
            <li>pH value: 4-5</li>
            <li>Solubility: Soluble in water and polar solvents</li>
            <li>Density: 1.01-1.03 g/cm3</li>
            <li>Viscosity: 100-300 cP</li>
          </ul>
          <h2>Catalyst:</h2>
          <ul>
            <li>No catalyst is required for the use of ZAICRYSTAL-66.</li>
          </ul>
          <h2>Applications:</h2>
          <ul>
            <li>
              ZAICRYSTAL-66 is widely used in the textile industry for producing
              stiff finishes on a variety of fabrics including cotton, linen,
              and their blends.
            </li>
          </ul>
          <h2>Note:</h2>
          <ul>
            <li>
              It is recommended to add ZAICRYSTAL-66 slowly and with good
              agitation during the finishing process to ensure that it is evenly
              dispersed.
            </li>
          </ul>
          <h2>Storage and Shelf Life:</h2>
          <ul>
            <li>
              ZAICRYSTAL-66 should be stored in a cool, dry place away from
              direct sunlight. It has a shelf life of at least 1 year when
              stored under recommended conditions.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Zaicrystal-55",
      contentbox: (
        <>
          <div>
            <h2>ZAICRYSTAL-55</h2>
            <p>
              ZAICRYSTAL-55 is a resin specifically designed to produce rigid
              stiff finishes on textile materials. It provides a crisp and clean
              appearance to the fabric.
            </p>
            <h3>Properties:</h3>
            <ul>
              <li>
                ZAICRYSTAL-55 provides a rigid stiff finish to the fabric
                without any harshening of the handle.
              </li>
              <li>It has excellent resistance to water and washing.</li>
            </ul>
            <h3>Typical Properties:</h3>
            <ul>
              <li>Appearance: Clear to pale yellow liquid</li>
              <li>pH value: 4-5</li>
              <li>Solubility: Soluble in water and polar solvents</li>
              <li>Density: 1.10-1.15 g/cm3</li>
              <li>Viscosity: 1000-2000 cP</li>
            </ul>
            <h3>Catalyst:</h3>
            <p>No catalyst is required for the use of ZAICRYSTAL-55.</p>
            <h3>Applications:</h3>
            <p>
              ZAICRYSTAL-55 is widely used in the textile industry for producing
              rigid stiff finishes on a variety of fabrics including cotton,
              linen, and their blends.
            </p>
            <h3>Note:</h3>
            <p>
              It is recommended to add ZAICRYSTAL-55 slowly and with good
              agitation during the finishing process to ensure that it is evenly
              dispersed.
            </p>
            <h3>Storage and Shelf Life:</h3>
            <p>
              ZAICRYSTAL-55 should be stored in a cool, dry place away from
              direct sunlight. It has a shelf life of at least 1 year when
              stored under recommended conditions.
            </p>
          </div>
        </>
      ),
    },
    {
      title: "Zaifin OSC",
      contentbox: (
        <>
          <div>
            <h2>ZAIFIN OSC</h2>
            <p>
              ZAIFIN OSC is a one-shot finishing agent used to impart a soft and
              smooth hand feel to textiles.
            </p>
            <h3>Properties:</h3>
            <ul>
              <li>Provides a soft and elastic hand feel to fabrics.</li>
              <li>Imparts durable finish with good wash fastness.</li>
              <li>Easy to apply and handle.</li>
            </ul>
            <h3>Typical properties:</h3>
            <ul>
              <li>Appearance: Milky white liquid</li>
              <li>pH (10% solution): 5.5 - 6.5</li>
              <li>Ionicity: Nonionic</li>
            </ul>
            <h3>Catalyst:</h3>
            <p>Catalyst not required.</p>
            <h3>Applications:</h3>
            <ul>
              <li>Used for finishing cotton, polyester, and their blends.</li>
              <li>Suitable for finishing knitted and woven fabrics.</li>
            </ul>
            <h3>Note:</h3>
            <ul>
              <li>Should be applied by padding or exhaust method.</li>
              <li>
                Can be used alone or in combination with other finishing agents.
              </li>
            </ul>
            <h3>Storage and shelf life:</h3>
            <ul>
              <li>Should be stored in a cool and dry place.</li>
              <li>Protect from direct sunlight and freezing.</li>
              <li>Shelf life of 6 months under normal storage conditions.</li>
            </ul>
          </div>
        </>
      ),
    },
    {
      title: "Zaigain-WT",
      contentbox: (
        <>
          <h2>ZAIGAIN-WT</h2>
          <p>
            ZAIGAIN-WT is a liquid flame retardant finish for cellulosic fabrics
            that imparts excellent flame retardant properties while maintaining
            the original fabric properties.
          </p>
          <h3>Properties:</h3>
          <ul>
            <li>
              Provides excellent flame retardant properties to cellulosic
              fabrics.
            </li>
            <li>Maintains the original fabric properties.</li>
            <li>Does not affect the color or handle of the fabric.</li>
          </ul>
          <h3>Typical properties:</h3>
          <ul>
            <li>Appearance: Colorless to yellowish liquid</li>
            <li>pH (1% solution): 5.5 - 7.5</li>
            <li>Solubility: Soluble in water</li>
            <li>Catalyst: Not required.</li>
          </ul>
          <h3>Applications:</h3>
          <ul>
            <li>
              Suitable for finishing cellulosic fabrics to impart flame
              retardancy.
            </li>
            <li>
              Used in industries such as home textiles, apparel, and upholstery.
            </li>
          </ul>
          <h3>Note:</h3>
          <ul>
            <li>
              The recommended dosage is 10-20 g/l of fabric for achieving the
              desired flame retardant properties.
            </li>
            <li>
              The finished fabric should be thoroughly washed and dried before
              use.
            </li>
            <li>The finished fabric should not be ironed above 200°C.</li>
          </ul>
          <h3>Storage and shelf life:</h3>
          <ul>
            <li>Store in a cool and dry place away from direct sunlight.</li>
            <li>
              The shelf life of the product is 12 months from the date of
              manufacturing.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Zaipaste-WH",
      contentbox: (
        <>
          <div>
            <h2>ZAIPASTE-WH</h2>
            <p>
              ZAIPASTE-WH is a white pigment paste based on titanium dioxide
              that is suitable for use in various textile applications.
            </p>
            <h3>Properties:</h3>
            <ul>
              <li>Provides excellent whiteness and opacity to fabrics.</li>
              <li>Good fastness properties to washing and light.</li>
              <li>Good compatibility with various binders and thickeners.</li>
            </ul>
            <h3>Typical properties:</h3>
            <ul>
              <li>Appearance: White paste</li>
              <li>Pigment content: 50% - 60%</li>
              <li>pH: 7.5 - 8.5</li>
              <li>Catalyst: Not required.</li>
            </ul>
            <h3>Applications:</h3>
            <ul>
              <li>
                Suitable for use in the textile industry for pigment printing
                and finishing of fabrics.
              </li>
              <li>
                Used in various textile applications such as home textiles,
                apparel, and upholstery.
              </li>
            </ul>
            <h3>Note:</h3>
            <ul>
              <li>
                The recommended dosage is 10-50 g/l depending on the desired
                opacity and shade.
              </li>
              <li>The paste should be thoroughly mixed before use.</li>
              <li>
                The finished fabric should be thoroughly washed and dried before
                use.
              </li>
            </ul>
            <h3>Storage and shelf life:</h3>
            <ul>
              <li>Store in a cool and dry place away from direct sunlight.</li>
              <li>
                The shelf life of the product is 12 months from the date of
                manufacturing.
              </li>
            </ul>
          </div>
        </>
      ),
    },
    {
      title: "ZTC TWL",
      contentbox: (
        <>
          <div>
            <h3>ZTC TWL</h3>
            <p>
              ZTC TWL is a wetting agent designed specifically for the towel
              industry, which enhances the water absorbency of the towel fabric.
            </p>
            <h4>Properties</h4>
            <ul>
              <li>Improves the wettability of towel fabric</li>
              <li>Increases the water absorbency of the towel fabric</li>
              <li>Provides a soft handle to the fabric</li>
            </ul>
            <h4>Typical properties</h4>
            <ul>
              <li>Appearance: Pale yellow liquid</li>
              <li>Active content: 100%</li>
              <li>pH: 7-9</li>
              <li>Ionicity: Nonionic</li>
            </ul>
            <h4>Applications</h4>
            <ul>
              <li>
                Used in towel finishing process for improving water absorbency
                and softness
              </li>
              <li>Can be applied by padding or spraying method</li>
            </ul>
            <p>
              Note: The dosage of ZTC TWL may vary depending on the type of
              towel fabric and the desired effect.
            </p>
            <h4>Storage</h4>
            <ul>
              <li>
                ZTC TWL should be stored in a cool and dry place, away from
                direct sunlight and heat sources.
              </li>
            </ul>
            <h4>Shelf life</h4>
            <ul>
              <li>12 months from the date of manufacture</li>
            </ul>
          </div>
        </>
      ),
    },
    {
      title: "Zaigell",
      contentbox: (
        <>
          <h1>Zaigell APN</h1>
          <p>
            Zaigell APN is a wetting agent that provides excellent wetting and
            penetration properties with low foam formation, suitable for various
            textile processing applications.
          </p>
          <h2>Properties:</h2>
          <ul>
            <li>Provides good wetting and penetration properties</li>
            <li>Low foaming</li>
            <li>APEO-free</li>
          </ul>
          <h2>Typical properties:</h2>
          <ul>
            <li>Appearance: Light yellow liquid</li>
            <li>Active content: 100%</li>
            <li>pH: 7-9</li>
            <li>Ionicity: Nonionic</li>
          </ul>
          <h2>Applications:</h2>
          <ul>
            <li>
              Suitable for various textile processing applications such as
              pretreatment, dyeing, printing, and finishing
            </li>
          </ul>
          <h2>Note:</h2>
          <ul>
            <li>
              Zaigell APN can be used in combination with other auxiliaries for
              better performance.
            </li>
            <li>
              The dosage of Zaigell APN may vary depending on the type of
              textile and processing conditions.
            </li>
          </ul>
          <h2>Storage:</h2>
          <ul>
            <li>
              Zaigell APN should be stored in a cool and dry place, away from
              direct sunlight and heat sources.
            </li>
          </ul>
          <h2>Shelf life:</h2>
          <ul>
            <li>12 months from the date of manufacture</li>
          </ul>
        </>
      ),
    },
    {
      title: "Zaigell JD",
      contentbox: (
        <>
          <div>
            <h2>Zaigell JD</h2>
            <p>
              Zaigell JD is a detergent specifically designed for all types of
              bleaching processes. It is an effective cleaning agent that can
              remove stains, dirt, and other impurities from fabric, resulting
              in a clean and bright finish.
            </p>
            <h3>Properties:</h3>
            <ul>
              <li>
                Effective cleaning agent for all types of bleaching processes
              </li>
              <li>Removes stains, dirt, and impurities from fabric</li>
            </ul>
            <h3>Typical properties:</h3>
            <ul>
              <li>Appearance: Clear liquid</li>
              <li>pH (1% solution): 7-8</li>
              <li>Active content: 20-22%</li>
              <li>Ionic character: Anionic</li>
            </ul>
            <p>Catalyst: None</p>
            <h3>Applications:</h3>
            <ul>
              <li>Suitable for use in all types of bleaching processes</li>
              <li>Can be used on a variety of fabric types</li>
            </ul>
            <h3>Note:</h3>
            <ul>
              <li>Follow recommended dosage guidelines for best results</li>
              <li>
                Always test on a small sample of fabric before use on larger
                quantities
              </li>
            </ul>
            <h3>Storage:</h3>
            <ul>
              <li>Store in a cool, dry place</li>
              <li>Protect from freezing</li>
            </ul>
            <h3>Shelf life:</h3>
            <p>12 months when stored properly in original packaging</p>
          </div>
        </>
      ),
    },
    {
      title: "Zaiwash WO",
      contentbox: (
        <>
          <div>
            <h2>Zaiwash WO</h2>
            <p>
              Zaiwash WO is a washing off agent for reactive dyeing. It is used
              to remove unfixed dye molecules from fabric after the dyeing
              process, resulting in a clear and vibrant color.
            </p>
            <h3>Properties:</h3>
            <ul>
              <li>Removes unfixed dye molecules from fabric</li>
              <li>Helps to improve color fastness and color brightness</li>
            </ul>
            <h3>Typical properties:</h3>
            <ul>
              <li>Appearance: Clear liquid</li>
              <li>pH (1% solution): 7-8</li>
              <li>Ionic character: Anionic</li>
            </ul>
            <p>Catalyst: None</p>
            <h3>Applications:</h3>
            <ul>
              <li>Suitable for use in all types of bleaching processes</li>
              <li>Can be used on a variety of fabric types</li>
            </ul>
            <p>Note:</p>
            <ul>
              <li>Follow recommended dosage guidelines for best results</li>
              <li>
                Always test on a small sample of fabric before use on larger
                quantities
              </li>
            </ul>
            <h3>Storage:</h3>
            <ul>
              <li>Store in a cool, dry place</li>
              <li>Protect from freezing</li>
            </ul>
            <h3>Shelf life:</h3>
            <p>12 months when stored properly in original packaging</p>
          </div>
        </>
      ),
    },
    {
      title: "Zaiquest NA",
      contentbox: (
        <>
          <h1>Zaiquest NA</h1>
          <p>
            Zaiquest NA is a sequestering agent used to control water hardness
            in various industrial applications. It chelates with metal ions in
            water to prevent them from interfering with the desired chemical
            reactions.
          </p>
          <h2>Properties:</h2>
          <ul>
            <li>Acts as a sequestering agent for metal ions in water</li>
            <li>
              Prevents metal ions from interfering with chemical reactions
            </li>
            <li>Improves cleaning and processing efficiency</li>
          </ul>
          <h2>Typical properties:</h2>
          <ul>
            <li>Appearance: Clear liquid</li>
            <li>pH: 10-12</li>
            <li>Density: 1.1-1.2 g/cm3</li>
            <li>Catalyst: None</li>
          </ul>
          <h2>Applications:</h2>
          <ul>
            <li>
              Used in textile, pulp and paper, and other industrial processes to
              control water hardness
            </li>
            <li>Improves efficiency of cleaning and processing</li>
          </ul>
          <h2>Note:</h2>
          <ul>
            <li>
              Should be added in small quantities to prevent over-chelation of
              metal ions
            </li>
          </ul>
          <h2>Storage:</h2>
          <ul>
            <li>Store in a cool, dry place away from direct sunlight</li>
          </ul>
          <h2>Shelf life:</h2>
          <ul>
            <li>2 years</li>
          </ul>
        </>
      ),
    },
    {
      title: "Zaienzyme SE",
      contentbox: (
        <>
          <div>
            <h2>Zaienzyme SE</h2>
            <p>
              Zaienzyme SE is a high temperature stable amylase used for starch
              decomposition in various industrial applications. It breaks down
              starch into simple sugars, improving the efficiency of downstream
              processes.
            </p>
            <h3>Properties:</h3>
            <ul>
              <li>High temperature stability</li>
              <li>Breaks down starch into simple sugars</li>
              <li>Improves the efficiency of downstream processes</li>
            </ul>
            <h3>Typical properties:</h3>
            <ul>
              <li>Appearance: Light brown liquid</li>
              <li>pH: 5.5-6.5</li>
              <li>Activity: 10,000-30,000 U/ml Catalyst: Amylase enzyme</li>
            </ul>
            <h3>Applications:</h3>
            <ul>
              <li>
                Used in textile, food processing, and other industrial processes
                for starch decomposition
              </li>
              <li>Improves the efficiency of downstream processes</li>
            </ul>
            <p>
              <strong>Note:</strong> Should be used at the optimal temperature
              and pH range for maximum efficiency
            </p>
            <h3>Storage:</h3>
            <ul>
              <li>Store in a cool, dry place away from direct sunlight</li>
            </ul>
            <h3>Shelf life:</h3>
            <ul>
              <li>6 months</li>
            </ul>
          </div>
        </>
      ),
    },
    {
      title: "Zaistabe ST",
      contentbox: (
        <>
          <div>
            <h2>Zaistabe ST</h2>
            <p>
              Zaistabe ST is a stabilizer used for hydrogen peroxide bleaching.
            </p>
            <h3>Properties:</h3>
            <ul>
              <li>Effective in alkaline, acidic or neutral mediums.</li>
              <li>Stable and effective even at high temperature.</li>
            </ul>
            <h3>Typical Properties:</h3>
            <ul>
              <li>Appearance: Clear liquid</li>
              <li>pH value: 6.5-7.5</li>
              <li>Solubility: Completely soluble in water</li>
            </ul>
            <p>Catalyst: Hydrogen peroxide</p>
            <h3>Applications:</h3>
            <ul>
              <li>
                Zaistabe ST is used as a stabilizer for hydrogen peroxide
                bleaching in the textile industry.
              </li>
              <li>
                It is effective in alkaline, acidic or neutral mediums and is
                stable even at high temperature.
              </li>
            </ul>
            <p>Note:</p>
            <ul>
              <li>Use as per the recommended dosage for optimum results.</li>
              <li>
                It is recommended to test the compatibility of Zaistabe ST with
                other chemicals before use.
              </li>
              <li>Avoid contact with eyes and skin.</li>
            </ul>
            <h3>Storage:</h3>
            <p>Store in a cool and dry place, away from direct sunlight.</p>
            <h3>Shelf Life:</h3>
            <p>12 months from the date of manufacturing.</p>
          </div>
        </>
      ),
    },
    {
      title: "Zaibuff VB",
      contentbox: (
        <>
          <h2>Zaibuff VB</h2>
          <p>
            Zaibuff VB is an acid buffer used for disperse dyeing at elevated
            temperatures.
          </p>
          <h3>Properties:</h3>
          <ul>
            <li>Completely organic nature.</li>
            <li>No or very low side effect on shade.</li>
            <li>No side effect on shade brightness.</li>
            <li>
              Bright and bluish tone with disperse black preventing brownish
              dull black shade.
            </li>
          </ul>
          <h3>Typical Properties:</h3>
          <ul>
            <li>Appearance: Clear liquid</li>
            <li>pH value: 4.5-5.5</li>
            <li>Solubility: Completely soluble in water</li>
            <li>Catalyst: Disperse dyes</li>
          </ul>
          <h3>Applications:</h3>
          <ul>
            <li>
              Zaibuff VB is used as an acid buffer for disperse dyeing at
              elevated temperatures in the textile industry.
            </li>
            <li>
              It has a completely organic nature and has no or very low side
              effects on the shade or shade brightness.
            </li>
            <li>
              It provides a bright and bluish tone with disperse black,
              preventing a brownish dull black shade.
            </li>
          </ul>
          <h3>Note:</h3>
          <ul>
            <li>Use as per the recommended dosage for optimum results.</li>
            <li>
              It is recommended to test the compatibility of Zaibuff VB with
              other chemicals before use.
            </li>
            <li>Avoid contact with eyes and skin.</li>
          </ul>
          <h3>Storage:</h3>
          <ul>
            <li>Store in a cool and dry place, away from direct sunlight.</li>
            <li>Shelf Life: 12 months from the date of manufacturing.</li>
          </ul>
        </>
      ),
    },
    {
      title: "Soft CZP-20",
      contentbox: (
        <>
          <div>
            <h2>Soft CZP-20</h2>
            <p>
              Soft CZP-20 is a non-ionic softener paste used in the textile
              industry to impart a soft, fluffy hand feel to fabrics.
            </p>
            <h3>Properties:</h3>
            <ul>
              <li>
                Soft CZP-20 is a non-ionic softener paste that can be easily
                dispersed in water.
              </li>
              <li>It provides excellent softness and smoothness to fabrics.</li>
              <li>It does not affect the shade or color of the fabric.</li>
              <li>Soft CZP-20 is stable under high-temperature conditions.</li>
            </ul>
            <h3>Typical Properties:</h3>
            <ul>
              <li>Appearance: White to light yellow paste</li>
              <li>pH: 6.0-7.5</li>
              <li>Solid Content: 60-62%</li>
              <li>Ionic Character: Non-ionic</li>
            </ul>
            <h3>Catalyst:</h3>
            <p>None</p>
            <h3>Applications:</h3>
            <ul>
              <li>
                Soft CZP-20 can be used on a wide range of fabrics such as
                cotton, polyester, and blends.
              </li>
              <li>It can be used in exhaust or padding applications.</li>
            </ul>
            <h3>Note:</h3>
            <ul>
              <li>Soft CZP-20 should be diluted with water before use.</li>
              <li>The recommended dosage is 1-3% on the weight of fabric.</li>
              <li>The curing temperature should be kept between 120-150°C.</li>
            </ul>
            <h3>Storage:</h3>
            <ul>
              <li>Soft CZP-20 should be stored in a cool, dry place.</li>
              <li>
                It should be protected from freezing and exposure to direct
                sunlight.
              </li>
            </ul>
            <h3>Shelf Life:</h3>
            <p>
              Soft CZP-20 has a shelf life of 6 months from the date of
              manufacture.
            </p>
          </div>
        </>
      ),
    },
    {
      title: "Soft AZS-26",
      contentbox: (
        <>
          <div>
            <h1>Soft AZS-26</h1>
            <p>
              Intro: Soft AZS-26 is a special softener used in the textile
              industry to provide a soft, silky touch with body to fabrics.
            </p>
            <h2>Properties:</h2>
            <ul>
              <li>
                Soft AZS-26 provides excellent softness, smoothness, and a silky
                feel to fabrics.
              </li>
              <li>It does not affect the shade or color of the fabric.</li>
              <li>It is stable under high-temperature conditions.</li>
              <li>Soft AZS-26 is compatible with most textile auxiliaries.</li>
            </ul>
            <h2>Typical Properties:</h2>
            <ul>
              <li>Appearance: Colorless to light yellow liquid</li>
              <li>pH: 5.5-6.5</li>
              <li>Solid Content: 30-32%</li>
              <li>Ionic Character: Cationic</li>
              <li>Catalyst: None</li>
            </ul>
            <h2>Applications:</h2>
            <ul>
              <li>
                Soft AZS-26 can be used on a wide range of fabrics such as
                cotton, polyester, and blends.
              </li>
              <li>It can be used in exhaust or padding applications.</li>
            </ul>
            <h2>Note:</h2>
            <ul>
              <li>Soft AZS-26 should be diluted with water before use.</li>
              <li>The recommended dosage is 1-3% on the weight of fabric.</li>
              <li>The curing temperature should be kept between 120-150°C.</li>
            </ul>
            <h2>Storage:</h2>
            <ul>
              <li>Soft AZS-26 should be stored in a cool, dry place.</li>
              <li>
                It should be protected from freezing and exposure to direct
                sunlight.
              </li>
            </ul>
            <h2>Shelf Life:</h2>
            <ul>
              <li>
                Soft AZS-26 has a shelf life of 6 months from the date of
                manufacture.
              </li>
            </ul>
          </div>
        </>
      ),
    },
    {
      title: "Hydrasill-ZF",
      contentbox: (
        <>
          <div>
            <h2>Hydrasill-ZF</h2>
            <p>Hydrasill-ZF is a non-ionic softener in the form of beads.</p>
            <h3>Properties:</h3>
            <ul>
              <li>
                Hydrasill-ZF provides a soft and smooth hand feel to the fabric.
              </li>
              <li>
                It is also effective in reducing static and improving the drape
                of the fabric.
              </li>
            </ul>
            <h3>Typical properties:</h3>
            <ul>
              <li>Appearance - White beads</li>
              <li>Ionic nature - Non-ionic</li>
              <li>pH (1% solution) - 5.0-7.0</li>
              <li>Solid content - 100%</li>
              <li>Melting point - 50-60°C</li>
            </ul>
            <h3>Catalyst:</h3>
            <p>None required.</p>
            <h3>Applications:</h3>
            <p>
              Hydrasill-ZF is suitable for use in the finishing process of all
              types of fabrics including cotton, polyester, and their blends. It
              can be added to the last rinse bath or padding bath.
            </p>
            <h3>Note:</h3>
            <ul>
              <li>
                Hydrasill-ZF should be used at a dosage of 0.3-1% on weight of
                fabric.
              </li>
              <li>
                It is recommended to dissolve the beads in hot water before
                adding to the bath.
              </li>
            </ul>
            <h3>Storage:</h3>
            <p>
              Store in a cool and dry place. Protect from frost and moisture.
            </p>
            <h3>Shelf life:</h3>
            <p>12 months from the date of manufacture.</p>
          </div>
        </>
      ),
    },
    {
      title: "Zaisilk SM-40",
      contentbox: (
        <>
          <div>
            <h2>Zaisilk SM-40</h2>
            <p>
              Zaisilk SM-40 is a macro emulsion used for universal applications
              in the textile industry. It imparts a soft, smooth, and elastic
              touch to fabrics.
            </p>
            <h3>Properties:</h3>
            <ul>
              <li>
                Provides softness and elasticity to woven and knitted fabrics
              </li>
              <li>
                Improves wash-and-wear properties, crease recovery angle, and
                tear strength
              </li>
              <li>Reduces abrasion loss</li>
              <li>Fast effects to washing and dry cleaning</li>
              <li>Little effect on degree of whiteness</li>
              <li>
                Generally, no adverse effect on shade or colour fastness
                properties
              </li>
            </ul>
            <h3>Typical Properties:</h3>
            <ul>
              <li>Appearance: Milky white liquid</li>
              <li>pH (as is): 6.0-7.0</li>
              <li>Ionicity: Nonionic</li>
              <li>Solid content: 40±1%</li>
              <li>
                Compatibility: Good with other softeners, resins and finishing
                agents
              </li>
              <li>
                Catalyst: No specific catalyst is required for this product.
              </li>
            </ul>
            <h3>Applications:</h3>
            <p>
              Zaisilk SM-40 is suitable for impregnation of fibers and textiles.
              It can be used for finishing various fabrics, including cotton,
              polyester, and their blends.
            </p>
            <h3>Note:</h3>
            <p>
              It is important to adhere to usual application conditions to
              minimize any adverse effects on the degree of whiteness or shade
              of the fabric.
            </p>
            <h3>Storage:</h3>
            <p>
              Zaisilk SM-40 should be stored in a cool and dry place, away from
              direct sunlight and heat sources.
            </p>
            <h3>Shelf Life:</h3>
            <p>
              The product has a shelf life of 6 months when stored properly in
              its original packaging.
            </p>
          </div>
        </>
      ),
    },
    {
      title: "Supercoat ZP",
      contentbox: (
        <>
          <div>
            <h2>Supercoat ZP</h2>
            <p>
              Supercoat ZP is a polyvinyl acetate (PVAc) emulsion that imparts a
              soft hand to various textiles and fabrics.
            </p>
            <h3>Properties:</h3>
            <ul>
              <li>Softens and smoothens the hand feel of fabrics</li>
              <li>Provides good adhesion to a variety of substrates</li>
              <li>Improves abrasion resistance</li>
              <li>Low foaming</li>
              <li>Easy to handle and apply</li>
            </ul>
            <h3>Typical properties:</h3>
            <ul>
              <li>Solid content: 50%</li>
              <li>pH: 4-5</li>
              <li>Viscosity: 500-1000 cps</li>
              <li>Appearance: Milky white liquid</li>
              <li>Catalyst: No catalyst is required for application.</li>
            </ul>
            <h3>Applications:</h3>
            <ul>
              <li>
                Suitable for finishing various textiles and fabrics, such as
                cotton, wool, and synthetic fibers
              </li>
              <li>
                Provides a soft hand and improves the drape and feel of the
                fabric
              </li>
              <li>Can be used as a base for pigment printing</li>
            </ul>
            <h3>Note:</h3>
            <ul>
              <li>
                Compatibility with other finishing agents should be tested
                before use
              </li>
              <li>
                The recommended dosage varies depending on the desired effect
                and the substrate
              </li>
            </ul>
            <h3>Storage:</h3>
            <ul>
              <li>
                Store in a cool and dry place, away from direct sunlight and
                heat sources
              </li>
              <li>
                Avoid freezing and prolonged exposure to high temperatures
              </li>
            </ul>
            <h3>Shelf life:</h3>
            <p>
              Can be stored for up to 6 months from the date of manufacture, if
              stored under recommended conditions.
            </p>
          </div>
        </>
      ),
    },
  ];

  let { exact } = useParams();
  exact = exact.replaceAll("_", " ");

  const chemical = textileChemicals.filter(
    (chemical) => chemical.title.toLowerCase() === exact.toLocaleLowerCase()
  )[0];

  if (chemical !== undefined) {
    return (
      <section className="project-detail">
        <div className="auto-container">
          <div className="inner-container">
            <NavLink to={-1} className="read-more a return">
              Return
            </NavLink>
            <div className="lower-content">{chemical.contentbox}</div>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className="project-detail">
        <div className="auto-container">
          <div className="inner-container">
            {/* <!-- Title Box --> */}
            <div className="title-box">
              <h2>404</h2>
              <div className="text">
                No documentation on this product. <br /> <br />
                <NavLink to={-1}>Return to Product</NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
