import React from 'react';
import "./Feature.css";

export default function Feature() {
    return (
        <section className="projects-feautres">
            <div className="auto-container">
                <div className="row">
                    {/* <!-- Text Column --> */}
                    <div className="text-column col-lg-6 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="sec-title">
                                <span className="title">FEATURE Products</span>
                                <h2>Quality Objective</h2>
                                <div className="devider"><span className="fa fa-cogs"></span></div>
                            </div>
                            <div className="text">Zaichem commits to continually improve the value of products and services. Our goal is to deliver quality products that meet or exceed customer requirements while always operating fairly and honestly with customers, suppliers and employees.</div>
                            <div className="btn-box"><a href="#learn" className="theme-btn btn-style-two a">Our Products</a></div>
                        </div>
                    </div>

                    {/* <!-- Blocks Column --> */}
                    <div className="blocks-column col-lg-6 col-md-12 col-sm-12">
                        {/* <!-- Feature BLock --> */}
                        <div className="feature-block ">
                            <div className="inner-box">
                                <span className="icon flaticon-idea"></span>
                                <h4>Creative Ideas</h4>
                                <div className="text">Idea Infinity is fully committed to progress and the pursuit of excellence within the services industry.</div>
                            </div>
                        </div>

                        {/* <!-- Feature BLock --> */}
                        <div className="feature-block block-two">
                            <div className="inner-box">
                                <span className="icon flaticon-tick-1"></span>
                                <h4>Super Safety</h4>
                                <div className="text">our business strategies, performance and integrative relationships with the business partners, suppliers, clients and each other.</div>
                            </div>
                        </div>

                        {/* <!-- Feature BLock --> */}
                        <div className="feature-block block-three">
                            <div className="inner-box">
                                <span className="icon flaticon-worker"></span>
                                <h4>24/7 Support</h4>
                                <div className="text">We provide complete gratification to our customers by providing them quality services and support at affordable prices.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
