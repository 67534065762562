import React from "react";
import "./AboutUs.css";
import { NavLink } from "react-router-dom";
import image from "../../assets/img/industry/28.jpeg";
import { useLocation } from "react-router-dom";
export default function AboutUs() {
  const url = useLocation();
  return (
    <section className="about-section">
      <div className="auto-container">
        <div className="row">
          {/* <!-- Content Column --> */}
          <div className="content-column col-lg-6 col-md-12 col-sm-12">
            <div className="inner-column">
              <span className="title">Welcome to </span>
              <h2>Zai Chemicals</h2>
              <div className="message">
                <span>Zai chem</span> is the production of goods or related
                services here with in an economy indicator of its relevant.
              </div>
              {url && url.pathname === "/" ? (
                <>
                  <div className="text" style={{ textAlign: "justify" }}>
                    ZAICHEM established in 2004 owned by the Yusuf Zai Family.
                    It engaged in Textile Business, Distribution, Trading,
                    Polymerization and reactions for auxiliaries, manufacturing
                    every kind of Chemicals from sizing to finishing and other
                    Specialty Polymers.
                  </div>
                  <blockquote className="blockquote">
                    <cite>Asif Ul Haq Khan, CEO</cite>
                    <p>
                      Many of our registered employees are requested as main
                      preferred temporary staff when a service.
                    </p>
                    <span className="icon fa fa-quote-right"></span>
                  </blockquote>
                  <div className="btn-box">
                    <NavLink to="/about" className="theme-btn btn-style-one a">
                      <span className="btn-title">More About</span>{" "}
                      <span className="icon fa fa-angle-double-right"></span>
                    </NavLink>
                  </div>
                </>
              ) : (
                <>
                  <div className="text" style={{ textAlign: "justify" }}>
                    Zai-Chem is a leading industrial service provider dedicated
                    to delivering high-quality solutions to businesses across
                    various industries. Our company is committed to providing
                    innovative, efficient, cost-effective services that help
                    clients achieve their operational goals and objectives. With
                    a highly skilled and experienced professional team, we
                    specialize in providing a wide range of industrial services,
                    including engineering, fabrication, installation,
                    maintenance, repair, and overhaul. We have a proven track
                    record of delivering top-notch services to clients in
                    various sectors, such as petrochemicals, oil and gas, power
                    generation, mining, and construction. At Zai-Chem, we pride
                    ourselves on our ability to provide customized solutions
                    tailored to meet our clients' specific needs and
                    requirements. We use the latest technologies and industry
                    best practices to ensure our clients receive the highest
                    quality, safety, and reliability in all our services. Our
                    commitment to excellence, safety, and customer satisfaction
                    has earned us a reputation as a trusted partner and service
                    provider to many of the world's leading industrial
                    companies. Whether you need engineering design, fabrication,
                    installation, maintenance, or repair services, you can count
                    on Zai-Chem to deliver exceptional results that exceed your
                    expectations.
                  </div>
                  {/* <blockquote className="blockquote">
                <cite>Asif Ul Haq Khan, CEO</cite>
                <p>
                  Many of our registered employees are requested as main
                  preferred temporary staff when a service.
                </p>
                <span className="icon fa fa-quote-right"></span>
              </blockquote> */}
                  <div className="btn-box">
                    {/* <NavLink to="/about" className="theme-btn btn-style-one a">
                  <span className="btn-title">More About</span>{" "}
                  <span className="icon fa fa-angle-double-right"></span>
                </NavLink> */}
                  </div>
                </>
              )}
              {/* <div className="text" style={{ textAlign: "justify" }}>
                Zai-Chem is a leading industrial service provider dedicated to
                delivering high-quality solutions to businesses across various
                industries. Our company is committed to providing innovative,
                efficient, cost-effective services that help clients achieve
                their operational goals and objectives. With a highly skilled
                and experienced professional team, we specialize in providing a
                wide range of industrial services, including engineering,
                fabrication, installation, maintenance, repair, and overhaul. We
                have a proven track record of delivering top-notch services to
                clients in various sectors, such as petrochemicals, oil and gas,
                power generation, mining, and construction. At Zai-Chem, we
                pride ourselves on our ability to provide customized solutions
                tailored to meet our clients' specific needs and requirements.
                We use the latest technologies and industry best practices to
                ensure our clients receive the highest quality, safety, and
                reliability in all our services. Our commitment to excellence,
                safety, and customer satisfaction has earned us a reputation as
                a trusted partner and service provider to many of the world's
                leading industrial companies. Whether you need engineering
                design, fabrication, installation, maintenance, or repair
                services, you can count on Zai-Chem to deliver exceptional
                results that exceed your expectations.
              </div>
               <blockquote className="blockquote">
                <cite>Asif Ul Haq Khan, CEO</cite>
                <p>
                  Many of our registered employees are requested as main
                  preferred temporary staff when a service.
                </p>
                <span className="icon fa fa-quote-right"></span>
              </blockquote> 
              <div className="btn-box">
                 <NavLink to="/about" className="theme-btn btn-style-one a">
                  <span className="btn-title">More About</span>{" "}
                  <span className="icon fa fa-angle-double-right"></span>
                </NavLink> 
              </div> */}
            </div>
          </div>
          {/* <!-- Image Column --> */}
          <div className="image-column col-lg-6 col-md-12 col-sm-12">
            <div className="image-box">
              <figure className="image">
                <img src={image} alt="" width="470" height="560" />
              </figure>
              {/* <span className="link lightbox-image"><span className="fa fa-play"></span></span> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
