import React, { useState } from "react";
import "./Projects.css";
import { NavLink } from "react-router-dom";
// import { NavLink, useParams } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import bgImg from "../../assets/img/industry/13.jpeg";
import img1 from "../../assets/img/products/1.jpg";
import img2 from "../../assets/img/products/2.jpg";
import img3 from "../../assets/img/products/3.jpg";
import img4 from "../../assets/img/products/4.jpg";
import img5 from "../../assets/img/products/5.jpg";
import img6 from "../../assets/img/products/6.jpg";
import img7 from "../../assets/img/products/7.jpg";
import img8 from "../../assets/img/products/8.jpg";
import img9 from "../../assets/img/products/9.jpg";
import img10 from "../../assets/img/products/10.jpg";
import img11 from "../../assets/img/products/11.jpg";
import b1 from "../../assets/img/products/crystal glue/1.jpeg";
import b2 from "../../assets/img/products/elephant bond/1.jpeg";
import b3 from "../../assets/img/products/euro lith/1.jpeg";
import b4 from "../../assets/img/products/grip lith/1.jpeg";
import b5 from "../../assets/img/products/products/1.jpeg";

// export const Project = () => {
//   const { name } = useParams();
//   const project = name.replaceAll("_", " ");
//   const ourProjects = [
//     {
//       name: "Sizing Chemical",
//       category: "Chemicals",
//       img: img1,
//       banner: b1,
//     },
//     {
//       name: "Detergents & Wetting Agent",
//       category: "Agents",
//       img: img2,
//       banner: b2,
//       chemicals: [
//         {
//           name: "Zairem SR",
//           desc: "ZAIREM SR is a non ionic, APEO/NPEO free wetting and stain removing agent meant for processing of cotton and synthetic fiber fabrics or their blends.",
//           benefits: [
//             "Very good detergency, good rewetting and excellent stain removing properties.",
//             "Suitable for boiling off, kier boiling and bleaching process.",
//             "Uniform extraction of oils, waxes and other impurities to produce an absorbent and clean structure for level dying, printing and white finishing etc.",
//             "ZAIREM SR can be used to disperse the solids generated by precipitation, coagulation and other chemical reactions.",
//             "ZAIREM SR cam be employed in a variety of refining, mixing, impregnating and surface-treatment processes.",
//           ],
//         },
//         {
//           name: "ZTC TWL",
//           desc: "Wetting agent for towel industry. ",
//         },
//         {
//           name: "Zaigell",
//           desc: "APEO free wetting agent with low foam",
//         },
//         {
//           name: "Zaigell JD",
//           desc: "Detergent for all type bleaching processes.",
//         },
//         {
//           name: "Zaiwash WO",
//           desc: "washing off for reactive dyeing.",
//         },
//         {
//           name: "Zaiquest NA",
//           desc: "sequestering agent to control water hardness.",
//         },
//       ],
//     },
//     {
//       name: "Enzyme & Desizing Agent",
//       category: "Agents",
//       img: img3,
//       banner: b3,
//       chemicals: [
//         {
//           name: "Zaienzyme SE",
//           desc: "high temperature stable amylase for starch decomposition.",
//         },
//       ],
//     },
//     {
//       name: "Stablizer & Buffer Acid",
//       category: "Acids",
//       img: img4,
//       banner: b4,
//       chemicals: [
//         {
//           name: "Zaistabe ST",
//           desc: "stabilizer for hydrogen per oxide bleaching.",
//         },
//         {
//           name: "Zaibuff VB",
//           desc: "Acid buffer for disperse dyeing at elevated temperatures.",
//         },
//       ],
//     },
//     {
//       name: "Softeners",
//       category: "Chemicals",
//       img: img5,
//       banner: b5,
//       chemicals: [
//         {
//           name: "Soft CZP-20",
//           desc: "Non ionic softener paste for fluffy hand.",
//         },
//         {
//           name: "Soft AZS-26",
//           desc: "special softener with body and silky touch.",
//         },
//         {
//           name: "Zaisoft TW",
//           desc: "Hydrophilic softener for towel and knitted fabrics.",
//         },
//         {
//           name: "Hydrasill-ZF",
//           desc: "Non ionic Softener beats.",
//         },
//       ],
//     },
//     {
//       name: "Silicon Emultions",
//       category: "Chemicals",
//       img: img6,
//       banner: b5,
//       chemicals: [
//         {
//           name: "Zaisilk SM-50",
//           desc: "semi micro emulsion having surface glazz and soft hand.",
//         },
//         {
//           name: "Zaisilk SM-40",
//           desc: "macro emulsion for universal application.",
//         },
//         {
//           name: "Zaisilk ME-40",
//           desc: "double amino modified silicone micro emulsion.",
//         },
//       ],
//     },
//     {
//       name: "PVA (Poly Vinyl Acetate)",
//       category: "Chemicals",
//       img: img7,
//       banner: b5,
//       chemicals: [
//         {
//           name: "Zaivil-50,35,30",
//           desc: "Belongs to the polyvinyl esters family",
//         },
//         {
//           name: "Supercoat ZP",
//           desc: "PVAc emulsion with soft hand.",
//         },
//       ],
//     },
//     {
//       name: "Dye Fixing Agent",
//       category: "Agents",
//       img: img8,
//       banner: b5,
//       chemicals: [
//         {
//           name: "Zaifix RP-50",
//           desc: "very low formaldehyde based dye fixative.",
//         },
//         {
//           name: "Zaifix RPR",
//           desc: "highly concentrated Pigment Fixer.",
//         },
//       ],
//     },
//     {
//       name: "Resin",
//       category: "Chemicals",
//       img: img9,
//       banner: b5,
//       chemicals: [
//         {
//           name: "Zaipil-NG",
//           desc: "Urea-glyoxal-formaldehyde cellulose reactant (1,3-dimethylol-4,5-dihydroxy-2-imidazolidinone).",
//         },
//         {
//           name: "ZAICREASE ACA",
//           desc: "",
//         },
//         {
//           name: "Zaigain WT",
//           desc: "Weight enhancing agent.",
//         },
//       ],
//     },
//     {
//       name: "Dispersing & Levelling Agent",
//       category: "Agents",
//       img: img10,
//       banner: b5,
//       chemicals: [
//         {
//           name: "ZAIPERSOL-K",
//           desc: "Leveling agent for reactive and direct dyeing",
//         },
//         {
//           name: "ZAIPERSOL-SK",
//           desc: "Leveling agent for high temperature and low foaming properties.",
//         },
//         {
//           name: "ZAIPERSOL-AL",
//           desc: "Acrylic based leveling and dispersing agent for pigment ink pastes.",
//         },
//       ],
//     },
//     {
//       name: "Finishing Agent",
//       category: "Agents",
//       img: img11,
//       banner: b5,
//       chemicals: [
//         {
//           name: "ZAICRYSTAL-66",
//           desc: "Finishing agent generally for stiff finishes",
//         },
//         { name: "ZAICRYSTAL-55", desc: "Resin for rigid stiff finish" },
//         { name: "ZAIFIN OSC", desc: "One shot finish for soft hand" },
//         {
//           name: "ZAIGAIN-WT",
//           desc: "Flame retardant finish (Liquid) for cellulosic fabric only.",
//         },
//         { name: "ZAIPASTE-WH", desc: "Titanium base white pigment paste." },
//       ],
//     },
//     {
//       name: "BINDERS",
//       chemicals: [
//         {
//           name: "Zaibind ZT",
//           desc: "Acrylic binder for water based pigment printing",
//         },
//         {
//           name: "Zaibind TP",
//           desc: "Pigment binder especially designed for table printing",
//         },
//         { name: "Zaibind LRT", desc: "Specified binder for leather industry" },
//         {
//           name: "Zaibind HCT",
//           desc: "Special binder for thermal back coating",
//         },
//       ],
//     },
//   ];

//   const ourProject = ourProjects.filter(
//     (ourProject) => ourProject.name === project
//   );

//   return (
//     <section className="project-section">
//       {/* <div className="layer-image">
//         <img src={bgImg} alt="" />
//       </div> */}
//       <div className="auto-container">
//         <div className="sec-title text-center">
//           <span className="title">{ourProject[0].category}</span>
//           <h2>{ourProject[0].name}</h2>
//           <div className="devider">
//             <span className="fa fa-cogs"></span>
//           </div>
//         </div>
//         <NavLink to="/products" className="read-more a return">
//           Return
//         </NavLink>
//         <div className="project-carousel owl-carousel owl-theme active">
//           {/* <Carousel
//             autoPlay={true}
//             emulateTouch={true}
//             infiniteLoop={true}
//             showArrows={false}
//             showStatus={false}
//             showIndicators={false}
//             showThumbs={true}
//           > */}
//           {ourProject.map((project, i) => {
//             return (
//               <div key={i} className="project-block">
//                 <table>
//                   {project.chemicals &&
//                     project.chemicals.map((chemical, i) => (
//                       <tr key={chemical.name}>
//                         <th className="table_key">
//                           <NavLink
//                             to={`/products/${project.name.replaceAll(
//                               " ",
//                               "_"
//                             )}/${chemical.name.replaceAll(" ", "_")}`}
//                           >
//                             {chemical.name}{" "}
//                           </NavLink>
//                         </th>
//                         <th className="table_value">{chemical.desc}</th>
//                       </tr>
//                     ))}
//                 </table>
//                 {/* <div className="inner-box">
//                     <div className="image-box">
//                       <figure className="image">
//                         <span className="lightbox-image">
//                           <img src={project.banner} alt="" />
//                         </span>
//                       </figure>
//                     </div>
//                     <div className="content-box">{project.contentBox}</div>
//                   </div> */}
//               </div>
//             );
//           })}

//           {/* </Carousel> */}
//         </div>

//         {/* <NavLink to="/products" className="a view-more">
//           View All
//         </NavLink> */}
//       </div>
//     </section>
//   );
// };

export default function Projects({ theme }) {
  const ourProjects = [
    {
      name: "Sizing Chemical",
      category: "Chemicals",
      img: img1,
      banner: b1,
      contentBox: (
        <>
          <h4>
            <NavLink to="/products" className="a">
              Oil Refinery Poart
            </NavLink>
          </h4>
          <div className="text">
            From field trips to teacher resources, MSI provides learning
            experiences both inside and outside the classroom—because improving
            science education is critical.
          </div>
          <NavLink to="/products" className="read-more a">
            Read More
          </NavLink>
        </>
      ),
    },
    {
      name: "Detergents & Wetting Agent",
      category: "Agents",
      img: img2,
      banner: b2,
      contentBox: (
        <>
          <h4>
            <NavLink to="/products" className="a">
              Oil Refinery Poart
            </NavLink>
          </h4>
          <div className="text">
            From field trips to teacher resources, MSI provides learning
            experiences both inside and outside the classroom—because improving
            science education is critical.
          </div>
          <NavLink to="/products" className="read-more a">
            Read More
          </NavLink>
        </>
      ),
    },
    {
      name: "Enzyme & Desizing Agent",
      category: "Agents",
      img: img3,
      banner: b3,
      contentBox: (
        <>
          <h4>
            <NavLink to="/products" className="a">
              Oil Refinery Poart
            </NavLink>
          </h4>
          <div className="text">
            From field trips to teacher resources, MSI provides learning
            experiences both inside and outside the classroom—because improving
            science education is critical.
          </div>
          <NavLink to="/products" className="read-more a">
            Read More
          </NavLink>
        </>
      ),
    },
    {
      name: "Stablizer & Buffer Acid",
      category: "Acids",
      img: img4,
      banner: b4,
      contentBox: (
        <>
          <h4>
            <NavLink to="/products" className="a">
              Oil Refinery Poart
            </NavLink>
          </h4>
          <div className="text">
            From field trips to teacher resources, MSI provides learning
            experiences both inside and outside the classroom—because improving
            science education is critical.
          </div>
          <NavLink to="/products" className="read-more a">
            Read More
          </NavLink>
        </>
      ),
    },
    {
      name: "Softeners",
      category: "Chemicals",
      img: img5,
      banner: b5,
      contentBox: (
        <>
          <h4>
            <NavLink to="/products" className="a">
              Oil Refinery Poart
            </NavLink>
          </h4>
          <div className="text">
            From field trips to teacher resources, MSI provides learning
            experiences both inside and outside the classroom—because improving
            science education is critical.
          </div>
          <NavLink to="/products" className="read-more a">
            Read More
          </NavLink>
        </>
      ),
    },
    {
      name: "Silicon Emultions",
      category: "Chemicals",
      img: img6,
      banner: b5,
      contentBox: (
        <>
          <h4>
            <NavLink to="/products" className="a">
              Oil Refinery Poart
            </NavLink>
          </h4>
          <div className="text">
            From field trips to teacher resources, MSI provides learning
            experiences both inside and outside the classroom—because improving
            science education is critical.
          </div>
          <NavLink to="/products" className="read-more a">
            Read More
          </NavLink>
        </>
      ),
    },
    {
      name: "PVA (Poly Vinyl Acetate)",
      category: "Chemicals",
      img: img7,
      banner: b5,
      contentBox: (
        <>
          <h4>
            <NavLink to="/products" className="a">
              Oil Refinery Poart
            </NavLink>
          </h4>
          <div className="text">
            From field trips to teacher resources, MSI provides learning
            experiences both inside and outside the classroom—because improving
            science education is critical.
          </div>
          <NavLink to="/products" className="read-more a">
            Read More
          </NavLink>
        </>
      ),
    },
    {
      name: "Dye Fixing Agent",
      category: "Agents",
      img: img8,
      banner: b5,
      contentBox: (
        <>
          <h4>
            <NavLink to="/products" className="a">
              Oil Refinery Poart
            </NavLink>
          </h4>
          <div className="text">
            From field trips to teacher resources, MSI provides learning
            experiences both inside and outside the classroom—because improving
            science education is critical.
          </div>
          <NavLink to="/products" className="read-more a">
            Read More
          </NavLink>
        </>
      ),
    },
    {
      name: "Resin",
      category: "Chemicals",
      img: img9,
      banner: b5,
      contentBox: (
        <>
          <h4>
            <NavLink to="/products" className="a">
              Oil Refinery Poart
            </NavLink>
          </h4>
          <div className="text">
            From field trips to teacher resources, MSI provides learning
            experiences both inside and outside the classroom—because improving
            science education is critical.
          </div>
          <NavLink to="/products" className="read-more a">
            Read More
          </NavLink>
        </>
      ),
    },
    {
      name: "Dispersing & Levelling Agent",
      category: "Agents",
      img: img10,
      banner: b5,
      contentBox: (
        <>
          <h4>
            <NavLink to="/products" className="a">
              Oil Refinery Poart
            </NavLink>
          </h4>
          <div className="text">
            From field trips to teacher resources, MSI provides learning
            experiences both inside and outside the classroom—because improving
            science education is critical.
          </div>
          <NavLink to="/products" className="read-more a">
            Read More
          </NavLink>
        </>
      ),
    },
    {
      name: "Finishing Agent",
      category: "Agents",
      img: img11,
      banner: b5,
      contentBox: (
        <>
          <h4>
            <NavLink to="/products" className="a">
              Oil Refinery Poart
            </NavLink>
          </h4>
          <div className="text">
            From field trips to teacher resources, MSI provides learning
            experiences both inside and outside the classroom—because improving
            science education is critical.
          </div>
          <NavLink to="/products" className="read-more a">
            Read More
          </NavLink>
        </>
      ),
    },
  ];
  const [filterProductsArray, setFilterProductsArray] = useState(ourProjects);
  const [isActive, setIsActive] = useState("All");
  const filterProducts = (item) => {
    setIsActive(item);
    item === "All"
      ? setFilterProductsArray(ourProjects)
      : setFilterProductsArray(
          ourProjects.filter((product) => product.category === item)
        );
  };
  let categoryArray = [];
  for (let value of ourProjects) {
    if (categoryArray.indexOf(value.category) === -1) {
      categoryArray.push(value.category);
    }
  }
  return (
    <>
      {theme === "two" ? (
        <section className="projects-page-section">
          <div className="auto-container">
            {/* <!--Sortable Masonry--> */}
            <div className="sortable-masonry">
              {/* <!--Filter--> */}
              <div className="filters">
                <ul className="filter-tabs filter-btns clearfix">
                  <li
                    onClick={() => filterProducts("All")}
                    className={isActive === "All" ? "filter active" : "filter"}
                  >
                    All
                  </li>
                  {categoryArray.map((category, i) => (
                    <li
                      key={i}
                      onClick={() => filterProducts(category)}
                      className={
                        isActive === category ? "filter active" : "filter"
                      }
                    >
                      {category}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="items-container row">
              {filterProductsArray.map((project, i) => {
                console.log(project.img);
                return (
                  <div
                    key={i}
                    className="project-block-two all masonry-item oil-and-gas construction col-lg-4 col-md-6 col-sm-12"
                  >
                    <div className="inner-box">
                      <div className="image-box">
                        <figure className="image">
                          <img src={project.img} alt="" />
                        </figure>
                      </div>
                      <div className="overlay-box">
                        <div className="upper-box">
                          <h4>
                            <NavLink to="/products" className="a">
                              {project.name}
                            </NavLink>
                          </h4>
                          <span className="category">{project.category}</span>
                        </div>
                        <NavLink
                          to={`/products/${project.name.replaceAll(" ", "_")}`}
                          className="link a"
                        >
                          Read More
                        </NavLink>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            {/* <div className="btn-box text-center">
              <NavLink to="/products" className="a theme-btn btn-style-two">
                View More <span className="fa fa-angle-double-right"></span>
              </NavLink>
            </div> */}
          </div>
        </section>
      ) : (
        <section className="project-section">
          <div className="layer-image">
            <img src={bgImg} alt="" />
          </div>
          <div className="auto-container">
            <div className="sec-title text-center">
              <span className="title">inspirations</span>
              <h2>Our Recent Products</h2>
              <div className="devider">
                <span className="fa fa-cogs"></span>
              </div>
            </div>
            <div className="project-carousel owl-carousel owl-theme active">
              <Carousel
                autoPlay={true}
                emulateTouch={true}
                infiniteLoop={true}
                showArrows={false}
                showStatus={false}
                showIndicators={false}
                showThumbs={true}
              >
                {ourProjects.map((project, i) => {
                  return (
                    <div key={i} className="project-block">
                      <div className="inner-box">
                        <div className="image-box">
                          <figure className="image">
                            <span className="lightbox-image">
                              <img src={project.banner} alt="" />
                            </span>
                          </figure>
                        </div>
                        <div className="content-box">{project.contentBox}</div>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
            <NavLink to="/products" className="a view-more">
              View All
            </NavLink>
          </div>
        </section>
      )}
    </>
  );
}
