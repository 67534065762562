import React from "react";
import "./TextileProduct.css";
import { Carousel } from "react-responsive-carousel";
// import img1 from "../../assets/images/product_detail/3.jpg";
// import img2 from "../../assets/images/product_detail/4.jpg";
// import img3 from "../../assets/images/resource/video-img.jpg";
import img1 from "../../assets/img/products/mrgrip/Griplith.jpg";
import img2 from "../../assets/img/products/mrgrip/Griplith.jpg";
import img3 from "../../assets/img/products/mrgrip/Mr.Grip Logo.jpg";

import { NavLink, useParams } from "react-router-dom";

export default function TextileProduct({ theme }) {
  const textileChemicals = [
    {
      title: "Mr Grip",
      sub: "Mr. Grip Super Glue",
      desc: `Mr.Grip Super Glue is a fast bonding, super strength, instant adhesive with specially engineered formulas and applicators to bond any material, deal for penetrating hard-to-bond cracks on non-porous surfaces.
      It works faster and holds stronger on more surfaces.  It works well on a variety of porous and non-porous surfaces. It dries clear and sets without clamping.
      Mr. Grip super glue can be easily used in a wide range of materials. Mr. Grip Super glue is high clear, and can dry instantly as well as applied easily. The materials can be bonded with just a little amount of it. Mr. Grip Super glue is all-purpose adhesive 

      Mr. Grip super glue bond is resistant to moisture, most chemicals and freezing temperatures.`,
      benefits: [
        "Invisible repairs",
        "No clamping required",
        "Pinpoint accuracy, easy to use",
        "Water Resistant",
        "Dries transparent",
        "Sets in seconds",
        "Easy squeeze grips",
      ],
      application: "",
    },
    {
      title: "Grip White Acrylic Latex",
      sub: "Grip White Acrylic Latex",
      desc: `Grip white is offering environmental, health, and safety benefits, as well as proven performance in real world exposures, Grip White is an excellent choice for painting in light-to medium-duty environments.  Grip White acrylic latex is designed and formulated to expand the utility of more demanding environments across a range of metal substrates, including structural steel, chemical and processing equipment, storage tanks and commercial architecture.`,
      benefits: [
        `Excellent corrosion resistance,
        durability, metal adhesion and
        formulation versatility. Based
        on Self-crosslinking Emulsion Technology. Low VOC
        capability (100 g/Lt or less).
        `,
      ],
      application: `New construction and maintenance
      painting of tanks, bridges, structural steel.
      Steel and concrete structures, railcars,
      Commercial architecture and DIY.
      `,
    },
    {
      title: "Wood Glue",
      desc: "Griplith White Glue is an exceptionally strong, economical white glue that sets faster than most other comparable glues. Its versatile formula is ideal for general woodworking applications, as well as many other porous and semi-porous materials. Griplith White Glue provides a strong initial tack and sets fast to reduce clamp time. It provides a translucent glue line, offers excellent sand ability and is unaffected by finishes. Griplith White Glue is easy to use, non-toxic and cleans up with water.",
      application: "",
      benefits: [],
    },
    {
      title: "Grip Bond",
      desc: "Grip bond is a reliable and general purpose shoe adhesive giving a tough resilient and heat resistant bond. ",
      application: "",
      benefits: [],
    },
  ];

  let { name } = useParams();
  name = name.replaceAll("-", " ");

  const chemical = textileChemicals.filter(
    (chemical) => chemical.title.toLowerCase() === name
  )[0];
  if (chemical !== undefined) {
    return (
      <section className="project-detail">
        <div className="auto-container">
          <div className="inner-container">
            {/* <!-- Title Box --> */}
            <div className="title-box">
              <h2>{chemical.title}</h2>
              <div className="text">
                Deals in all kind of textile auxiliaries and sizing material.
              </div>
            </div>
            <div className="image-box textile-logo">
              <figure className="image">
                <a
                  href="images/product_detail/3.jpg"
                  className="lightbox-image a"
                >
                  <img src={img3} alt="" height="inherit" />
                </a>
              </figure>
            </div>

            {chemical.benefits.length > 0 ? (
              <>
                <div className="lower-content">
                  <h3>Description</h3>
                  <p>{chemical.desc}</p>
                  <div className="two-column row">
                    <div className=" column col-lg-6 col-md-12 col-sm-12 order-2">
                      <h3>Features and Benefits</h3>
                      <ul className="benefits ">
                        {chemical.benefits.map((benefit) => (
                          <li key={benefit}>{benefit}</li>
                        ))}
                      </ul>
                      <br />
                      {chemical.application.length > 0 && <h3>Outcome</h3>}
                      <p>{chemical.application}</p>
                    </div>
                    <div className="column col-lg-5 col-md-12 col-sm-12">
                      <div className="video-box">
                        <a href="#play" className="link a">
                          <div className="single-item-carousel owl-carousel owl-theme">
                            <Carousel
                              autoPlay={true}
                              emulateTouch={true}
                              infiniteLoop={true}
                              showArrows={true}
                              showStatus={false}
                              showIndicators={true}
                              showThumbs={true}
                            >
                              <div className="image-box">
                                <figure className="image">
                                  <a
                                    href="images/product_detail/3.jpg"
                                    className="lightbox-image a"
                                  >
                                    <img src={img1} alt="" height="inherit" />
                                  </a>
                                </figure>
                              </div>

                              <div className="image-box">
                                <figure className="image">
                                  <a
                                    href="images/product_detail/4.jpg"
                                    className="lightbox-image a"
                                  >
                                    <img src={img2} alt="" height="inherit" />
                                  </a>
                                </figure>
                              </div>
                            </Carousel>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="lower-content">
                  <div className="two-column row">
                    <div className=" column col-lg-6 col-md-12 col-sm-12 order-2">
                      {/* <h3>Features and Benefits</h3> */}
                      <h3>Description</h3>
                      <p>{chemical.desc}</p>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <ul className="benefits ">
                        {chemical.benefits.map((benefit) => (
                          <li key={benefit}>{benefit}</li>
                        ))}
                      </ul>
                      <br />
                      {chemical.application.length > 0 && <h3>Outcome</h3>}
                      <p>{chemical.application}</p>
                    </div>
                    <div className="column col-lg-5 col-md-12 col-sm-12">
                      <div className="video-box">
                        <a href="#play" className="link a">
                          <div className="single-item-carousel owl-carousel owl-theme">
                            <Carousel
                              autoPlay={true}
                              emulateTouch={true}
                              infiniteLoop={true}
                              showArrows={true}
                              showStatus={false}
                              showIndicators={true}
                              showThumbs={true}
                            >
                              <div className="image-box">
                                <figure className="image">
                                  <a
                                    href="images/product_detail/3.jpg"
                                    className="lightbox-image a"
                                  >
                                    <img src={img1} alt="" height="inherit" />
                                  </a>
                                </figure>
                              </div>

                              <div className="image-box">
                                <figure className="image">
                                  <a
                                    href="images/product_detail/4.jpg"
                                    className="lightbox-image a"
                                  >
                                    <img src={img2} alt="" height="inherit" />
                                  </a>
                                </figure>
                              </div>
                            </Carousel>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {theme === "two" && (
              <div className="load-more-option">
                <ul className="clearfix">
                  <li className="prev pull-left">
                    <a className="a" href="#previous">
                      Previous
                    </a>
                  </li>
                  <li className="load-more">
                    <a className="a" href="#icon">
                      <span className="fa fa-th"></span>
                    </a>
                  </li>
                  <li className="next pull-right">
                    <a className="a" href="#next">
                      NEXT
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className="project-detail">
        <div className="auto-container">
          <div className="inner-container">
            {/* <!-- Title Box --> */}
            <div className="title-box">
              <h2>No product found</h2>
              <div className="text">
                The link your trying to reach may be expired. <br />
                <NavLink to="/">Return to Homepage</NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
