import React from 'react';
import "./Footer.css";
import { NavLink } from 'react-router-dom';
import logo from "../../assets/images/logo.png";

export default function Footer({ theme }) {
    let date = new Date();
    return (
        <footer className={theme === 'dark' ? 'main-footer style-two alternate' : 'main-footer'}>
            {/* <!--footer upper--> */}
            <div className="footer-upper">
                <div className="auto-container">

                    <div className="row">
                        <div className="big-column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                            <div className="row clearfix">
                                {/* <!--Footer Column--> */}
                                <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                                    <div className="footer-widget about-widget">
                                        <div className="logo">
                                            <NavLink className="a" to="/"><img src={logo} alt="" width="150" height="100" /></NavLink>
                                        </div>
                                        <div className="text">
                                            <p><strong>Industris</strong> has very good strength in innovative technology and tools with over 35 years of experience.</p>
                                            <p>We makes long-term investments goal in global companies in different sectors, mainly in Europe and other countries.</p>
                                        </div>
                                    </div>
                                </div>

                                {/* <!--Footer Column--> */}
                                <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                                    {/* <!--Footer Column--> */}
                                    <div className="footer-widget recent-posts">
                                        <h2 className="widget-title">Industry Segment</h2>
                                        {/* <!--Footer Column--> */}
                                        <div className="widget-content">
                                            <ul className="serivce-list clearfix">
                                                <li><a className="a" href="#abc">Sizing & Dyeing</a></li>
                                                <li><a className="a" href="#abc">Printing & Finishing</a></li>
                                                <li><a className="a" href="#abc">Pretreatment</a></li>
                                                <li><a className="a" href="#abc">Garment Washing</a></li>
                                                <li><a className="a" href="#abc">Optical Brightener</a></li>
                                                <li><a className="a" href="#abc">Denim Processing</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="big-column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                            <div className="row">
                                {/* <!--Footer Column--> */}
                                <div className="footer-column col-xl-5 col-lg-6 col-md-6 col-sm-12">
                                    <div className="footer-widget services-widget">
                                        <h2 className="widget-title">Our Products</h2>
                                        <div className="widget-content">
                                            <ul className="serivce-list clearfix">
                                                <li><a className="a" href="#abc">Detergents & Wetting Agents</a></li>
                                                <li><a className="a" href="#abc">Enzymes & Desizing Agents</a></li>
                                                <li><a className="a" href="#abc">Stablizer & Buffer Acids</a></li>
                                                <li><a className="a" href="#abc">Sizing Chemical</a></li>
                                                <li><a className="a" href="#abc">Softeners</a></li>
                                                <li><a className="a" href="#abc">Silicon Emultions</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/* <!--Footer Column--> */}
                                <div className="footer-column col-xl-7 col-lg-6 col-md-6 col-sm-12">
                                    <div className="footer-widget about-widget">
                                        <h2 className="widget-title">Contact Details</h2>
                                        <div className="widget-content">
                                            <div className="text">
                                                <p><strong><i className="fa fa-map-marker"></i> Address:</strong> <a className="a" href="#abc">Zaichem P-31, 1 KM Begewal Road, Opp Latif Garden, Manawala, Sheikhupura Road, Faisalabad.</a></p>
                                                <p><strong><i className="fa fa-phone"></i> Phone:</strong> <a className="a" href="#abc">+92-41-8756216</a></p>
                                                <p><strong><i className="fa fa-mobile-phone"></i> Mobile:</strong> <a className="a" href="#abc">+92-321-6626216</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!--Footer Bottom--> */}
            <div className="footer-bottom">
                <div className="auto-container">
                    <div className="inner-container clearfix">
                        <div className="footer-nav">
                            <ul className="clearfix">
                                <li><a className="a" href="facebook.com"><i className="fa fa-facebook-f"></i></a></li>
                                <li><a className="a" href="instagram.com"><i className="fa fa-instagram"></i></a></li>
                                <li><a className="a" href="twitter.com"><i className="fa fa-twitter"></i></a></li>
                            </ul>
                        </div>

                        <div className="copyright-text">
                            <p>{date.getFullYear()} © All Rights Reserved by <NavLink className="a" to="/">ZAI Chem</NavLink></p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
