// import React, { useState } from "react";
import React from "react";
import "./chemicals.css";
import { NavLink, useParams } from "react-router-dom";

import c1 from "../../assets/img/chemicals/1.png";
import c2 from "../../assets/img/chemicals/2.png";
import c3 from "../../assets/img/chemicals/3.png";
import c4 from "../../assets/img/chemicals/4.png";
import c5 from "../../assets/img/chemicals/zai cc 5.png";
import c6 from "../../assets/img/chemicals/zai cc 6.png";
import c7 from "../../assets/img/chemicals/zai cc 7.png";
import c8 from "../../assets/img/chemicals/zai cc 8.png";
import c9 from "../../assets/img/chemicals/zai cc 9.png";
import c10 from "../../assets/img/chemicals/zai cc 10.png";
import c11 from "../../assets/img/chemicals/zai cc 11.png";
import c12 from "../../assets/img/chemicals/zai cc12.png";
import c13 from "../../assets/img/chemicals/zai cc13.png";
import c14 from "../../assets/img/chemicals/zai cc 14.png";
import c15 from "../../assets/img/chemicals/zai cc 15.png";
import c16 from "../../assets/img/chemicals/zai cc 16.png";
import c17 from "../../assets/img/chemicals/zai cc 17.png";
import c18 from "../../assets/img/chemicals/zai cc 18.png";
import c19 from "../../assets/img/chemicals/zai cc 19.png";
import c20 from "../../assets/img/chemicals/zai cc 20.png";
import c21 from "../../assets/img/chemicals/zai cc 21.png";
import c22 from "../../assets/img/chemicals/zai cc 22.png";
import c23 from "../../assets/img/chemicals/zai cc 23.png";
import c24 from "../../assets/img/chemicals/zai cc 24.png";
import c25 from "../../assets/img/chemicals/zai cc 25.png";
import c26 from "../../assets/img/chemicals/zai cc 26.png";
import c27 from "../../assets/img/chemicals/zai cc 27.png";
import c28 from "../../assets/img/chemicals/zai cc 28.png";
import c29 from "../../assets/img/chemicals/zai cc 29.png";
import c30 from "../../assets/img/chemicals/zai cc 30.png";
import c31 from "../../assets/img/chemicals/zai cc 31.png";
import c32 from "../../assets/img/chemicals/zai cc 32.png";
import c33 from "../../assets/img/chemicals/zai cc 33.png";
import c34 from "../../assets/img/chemicals/zai cc 34.png";

export default function Chemicals({ theme }) {
  // const navigate = useNavigate();
  const { name } = useParams();

  const project = name.replaceAll("_", " ");
  const ourProjects = [
    {
      name: "Sizing Chemical",
      category: "Chemicals",

      chemicals: [],
    },
    {
      name: "Detergents & Wetting Agent",
      category: "Agents",

      chemicals: [
        {
          name: "Zairem SR",
          desc: "ZAIREM SR is a non ionic, APEO/NPEO free wetting and stain removing agent meant for processing of cotton and synthetic fiber fabrics or their blends.",
          img: c1,
          benefits: [
            "Very good detergency, good rewetting and excellent stain removing properties.",
            "Suitable for boiling off, kier boiling and bleaching process.",
            "Uniform extraction of oils, waxes and other impurities to produce an absorbent and clean structure for level dying, printing and white finishing etc.",
            "ZAIREM SR can be used to disperse the solids generated by precipitation, coagulation and other chemical reactions.",
            "ZAIREM SR cam be employed in a variety of refining, mixing, impregnating and surface-treatment processes.",
          ],
        },
        {
          name: "ZTC TWL",
          desc: "Wetting agent for towel industry. ",
          img: c2,
        },
        {
          name: "Zaigell",
          desc: "APEO free wetting agent with low foam",
          img: c3,
        },
        {
          name: "Zaigell JD",
          desc: "Detergent for all type bleaching processes.",
          img: c4,
        },
        {
          name: "Zaiwash WO",
          desc: "washing off for reactive dyeing.",
          img: c5,
        },
        {
          name: "Zaiquest NA",
          desc: "sequestering agent to control water hardness.",
          img: c6,
        },
      ],
    },
    {
      name: "Enzyme & Desizing Agent",
      category: "Agents",

      chemicals: [
        {
          name: "Zaienzyme SE",
          desc: "high temperature stable amylase for starch decomposition.",
          img: c7,
        },
      ],
    },
    {
      name: "Stablizer & Buffer Acid",
      category: "Acids",

      chemicals: [
        {
          name: "Zaistabe ST",
          desc: "stabilizer for hydrogen per oxide bleaching.",
          img: c8,
        },
        {
          name: "Zaibuff VB",
          desc: "Acid buffer for disperse dyeing at elevated temperatures.",
          img: c9,
        },
      ],
    },
    {
      name: "Softeners",
      category: "Chemicals",

      chemicals: [
        {
          name: "Soft CZP-20",
          desc: "Non ionic softener paste for fluffy hand.",
          img: c10,
        },
        {
          name: "Soft AZS-26",
          desc: "special softener with body and silky touch.",
          img: c11,
        },
        {
          name: "Zaisoft TW",
          desc: "Hydrophilic softener for towel and knitted fabrics.",
          img: c12,
        },
        {
          name: "Hydrasill-ZF",
          desc: "Non ionic Softener beats.",
          img: c13,
        },
      ],
    },
    {
      name: "Silicon Emultions",
      category: "Chemicals",

      chemicals: [
        {
          name: "Zaisilk SM-50",
          desc: "semi micro emulsion having surface glazz and soft hand.",
          img: c14,
        },
        {
          name: "Zaisilk SM-40",
          desc: "macro emulsion for universal application.",
          img: c15,
        },
        {
          name: "Zaisilk ME-40",
          desc: "double amino modified silicone micro emulsion.",
          img: c16,
        },
      ],
    },
    {
      name: "PVA (Poly Vinyl Acetate)",
      category: "Chemicals",

      chemicals: [
        {
          name: "Zaivil-50,35,30",
          desc: "Belongs to the polyvinyl esters family",
          img: c17,
        },
        {
          name: "Supercoat ZP",
          desc: "PVAc emulsion with soft hand.",
          img: c18,
        },
      ],
    },
    {
      name: "Dye Fixing Agent",
      category: "Agents",

      chemicals: [
        {
          name: "Zaifix RP-50",
          desc: "very low formaldehyde based dye fixative.",
          img: c19,
        },
        {
          name: "Zaifix RPR",
          desc: "highly concentrated Pigment Fixer.",
          img: c20,
        },
      ],
    },
    {
      name: "Resin",
      category: "Chemicals",

      chemicals: [
        {
          name: "Zaipil-NG",
          desc: "Urea-glyoxal-formaldehyde cellulose reactant (1,3-dimethylol-4,5-dihydroxy-2-imidazolidinone).",
          img: c21,
        },
        {
          name: "ZAICREASE ACA",
          desc: "",
          img: c22,
        },
        {
          name: "Zaigain WT",
          desc: "Weight enhancing agent.",
          img: c23,
        },
      ],
    },
    {
      name: "Dispersing & Levelling Agent",
      category: "Agents",

      chemicals: [
        {
          name: "ZAIPERSOL-K",
          desc: "Leveling agent for reactive and direct dyeing",
          img: c24,
        },
        {
          name: "ZAIPERSOL-SK",
          desc: "Leveling agent for high temperature and low foaming properties.",
          img: c25,
        },
        {
          name: "ZAIPERSOL-AL",
          desc: "Acrylic based leveling and dispersing agent for pigment ink pastes.",
          img: c26,
        },
      ],
    },
    {
      name: "Finishing Agent",
      category: "Agents",

      chemicals: [
        {
          name: "ZAICRYSTAL-66",
          desc: "Finishing agent generally for stiff finishes",
          img: c27,
        },
        {
          name: "ZAICRYSTAL-55",
          desc: "Resin for rigid stiff finish",
          img: c28,
        },
        {
          name: "ZAIFIN OSC",
          desc: "One shot finish for soft hand",
          img: c29,
        },
        {
          name: "ZAIGAIN-WT",
          desc: "Flame retardant finish (Liquid) for cellulosic fabric only.",
          img: c30,
        },
        {
          name: "ZAIPASTE-WH",
          desc: "Titanium base white pigment paste.",
          img: c31,
        },
      ],
    },
    {
      name: "BINDERS",
      chemicals: [
        {
          name: "Zaibind ZT",
          desc: "Acrylic binder for water based pigment printing",
          img: c31,
        },
        {
          name: "Zaibind TP",
          desc: "Pigment binder especially designed for table printing",
          img: c32,
        },
        {
          name: "Zaibind LRT",
          desc: "Specified binder for leather industry",
          img: c33,
        },
        {
          name: "Zaibind HCT",
          desc: "Special binder for thermal back coating",
          img: c34,
        },
      ],
    },
  ];

  const ourProject = ourProjects.filter(
    (ourProject) => ourProject.name === project
  );

  return (
    <>
      <section className="projects-page-section">
        <div className="auto-container">
          <div className="sortable-masonry">
            {/* <!--Filter--> */}
            <div className="filters">
              <ul className="filter-tabs filter-btns clearfix">
                <NavLink to={-1}>
                  <li className="All filter active"> Return </li>
                </NavLink>
              </ul>
            </div>
          </div>
          <div className="items-container row">
            {ourProject &&
              ourProject[0].chemicals.map((chemical, i) => {
                console.log(chemical.img);
                return (
                  <div
                    key={i}
                    className="project-block-two all masonry-item oil-and-gas construction col-lg-4 col-md-6 col-sm-12"
                  >
                    <div className="inner-box">
                      <div className="image-box">
                        <figure className="image">
                          <img src={chemical.img} alt={chemical.name} />
                        </figure>
                      </div>
                      <div className="overlay-box">
                        <div className="upper-box">
                          <h4>{chemical.name} </h4>
                          {/* <span className="category">{project.category}</span> */}
                        </div>
                        <NavLink
                          to={`${chemical.name.replaceAll(" ", "_")}`}
                          className="link a"
                        >
                          Read More
                        </NavLink>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
}
