import React from 'react';
import './ContactInfo.css';

export default function ContactInfo() {
    return (
        <section className="contact-info-section style-three">
            <div className="auto-container">
                <div className="row">
                    {/* <!-- Contact Info Block --> */}
                    <div className="title-column col-lg-5 col-md-12 col-sm-12">
                        <h2><span>Get</span> in Touch</h2>
                        <div className="text">You will find yourself working in a true partnership that results in an incredible experience, and an end product that is the best.</div>
                    </div>

                    {/* <!-- Block Column --> */}
                    <div className="block-column col-lg-7 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="row">
                                <div className="contact-info-block col-lg-6 col-md-6 col-sm-12">
                                    <div className="inner">
                                        <span className="icon flaticon-call-1"></span>
                                        <h3>Call us on</h3>
                                        <div className="info"><a className="a" href="tel:+92418756216">+92-41-8756216</a></div>
                                    </div>
                                </div>

                                <div className="contact-info-block col-lg-6 col-md-6 col-sm-12">
                                    <div className="inner">
                                        <span className="icon flaticon-mails"></span>
                                        <h3>Email us</h3>
                                        <div className="info"><a className="a" href="mailto:info@zaichem.com">info@zaichem.com</a></div>
                                    </div>
                                </div>

                                <div className="contact-info-block col-lg-6 col-md-6 col-sm-12">
                                    <div className="inner">
                                        <span className="icon flaticon-clock-2"></span>
                                        <h3>Opening Hours</h3>
                                        <div className="info">Monday - Satureday <br />9:00 am  to  7:00 pm</div>
                                    </div>
                                </div>

                                <div className="contact-info-block col-lg-6 col-md-6 col-sm-12">
                                    <div className="inner">
                                        <span className="icon flaticon-power"></span>
                                        <h3>Address</h3>
                                        <div className="info">Zaichem P-31, 1 KM Begewal Road, Faisalabad.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
